import { useTranslation } from "react-i18next";
import { Button } from "src/components/Button";
import useUser from "src/hooks/useUser";

const MyAccountView = () => {
  const user = useUser();
  const { t } = useTranslation();

  return (
    <>
      <div className="p-5">
        <dl className="my-6 space-y-6 divide-y divide-gray-50 border-y border-gray-100 text-sm leading-6">
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 hidden md:block sm:flex-none sm:pr-6">{t('auth.fields.crmId')}</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div>
                {user?.crmId}
              </div>
            </dd>
          </div>
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 hidden md:block sm:flex-none sm:pr-6">{t('auth.fields.givenName')}</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div>
                {user?.givenName}
              </div>
            </dd>
          </div>
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 hidden md:block sm:flex-none sm:pr-6">{t('auth.fields.surname')}</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div>

                {user?.surname}
              </div>
            </dd>
          </div>
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 hidden md:block sm:flex-none sm:pr-6">{t('auth.fields.email')}</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div>
                {user?.email}
              </div>
            </dd>
          </div>
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 hidden md:block sm:flex-none sm:pr-6">{t('auth.fields.phoneNumber')}</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div>
                {user?.phoneNumber}
              </div>
            </dd>
          </div>
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 hidden md:block sm:flex-none sm:pr-6">{t('common.fields.referralCode')}</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div>
                {user?.crmId}
              </div>
            </dd>
          </div>
          <div className="py-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 hidden md:block sm:flex-none sm:pr-6"></dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div>

              </div>
              <div>
                <Button color="rose">{t('myAccount.remove')}</Button>
              </div>
            </dd>
          </div>
        </dl>
      </div>
    </>
  );
}

export default MyAccountView;