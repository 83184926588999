import { EnabledRegions } from './clientRegion';
import { useRegion } from 'src/hooks/useRegion';
import { twMerge } from 'tailwind-merge';
import CountryName from './CountryName';
import Selector from '../Selector/Selector';
import SelectorOption from '../Selector/SelectorOption';
import { useTranslation } from 'react-i18next';
import { getCurrencyByRegion } from '../Pricing';
import { CountryFlag } from './CountryFlag';
import { useMemo } from 'react';

const regions = EnabledRegions;

export default function RegionSelector({
  label,
  short,
  anchor,
  withoutChevron,
  className
}: { label?: boolean, short?: boolean, withoutChevron?: boolean, anchor?: "top" | "bottom", className?: string }) {
  const { t } = useTranslation();
  const { region, setRegion } = useRegion();
  const currency = getCurrencyByRegion(region);

  const regionCategories = useMemo(
    () => Array.from(new Set(regions.map(r => r.category)))
      .sort((a, b) => a.localeCompare(b)),
    [regions]
  );

  return (
    <>
      <div className={twMerge("flex flex-col gap-y-3", className)}>
        {label && <span className="font-medium text-xs text-gray-500">{t('ui.region')}</span>}
        <Selector
          button={(!short ? <div className="flex gap-x-2">
            <CountryFlag countryCode={region} className="relative top-0.5" />
            <div><CountryName countryCode={region} /></div>
            <div>({currency})</div>
          </div> : <span className="pt-1"><CountryFlag countryCode={region} /></span>)}
          anchor={anchor}
          withoutChevron={withoutChevron}
        >
          {regionCategories.map((category) => (
            <div key={category}>
              <span key={`${category}-header`} className="text-xs uppercase text-gray-500">{t(`regions.categories.${category}`)}</span>
              {regions.filter(r => r.category === category).map((r) => (
                <SelectorOption onClick={() => setRegion(r.countryCode)} active={r.countryCode === region} key={r.countryCode}>
                  <div className="flex gap-x-3">
                    <div className="w-8 flex justify-end"><CountryFlag countryCode={r.countryCode} className="relative top-0.5" /></div>
                    <span className="mr-5"><CountryName countryCode={r.countryCode} /></span>
                    <span className="ml-auto">{getCurrencyByRegion(r.countryCode)}</span>
                  </div>
                </SelectorOption>
              ))}
            </div>
          ))}
        </Selector>
      </div>
    </>);
}

