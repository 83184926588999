import { Button } from 'src/components/Button'
import { SelectField, TextAreaField, TextField } from './Fields'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useApiConfiguration from 'src/hooks/useApiConfiguration'
import useForm, { IForm } from 'src/hooks/useForm'
import { useEffect, useMemo, useState } from 'react'
import useEventTracking from 'src/hooks/useEventTracking'

import useEntityTranslation from 'src/hooks/useEntityTranslation'
import { getCurrencyByRegion, priceValue, PriceValue } from './Pricing'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { EnabledRegions } from './i18n/clientRegion'
import { useRegion } from 'src/hooks/useRegion'
import CountryName from './i18n/CountryName'
import { LicensePeriod, Product, ProductPrice, ProductsClient, ProductTranslation, TenantApplyData, TenantRequestsClient } from 'src/api/licensing/Licensing'
import Feedback from './Feedback'
import Spinner from './Feedback/Spinner'
import PhoneNumberField from './PhoneNumberField'
import FormCheckbox from './Form/FormCheckbox'
import LocalizedLink from './Router/LocalizedLink'
import { faCheckCircle } from '@fortawesome/duotone-light-svg-icons'

export function PricePlanOption({ product, price }: { product: Product, price: ProductPrice, taxRate: number }) {
  const productTranslation = useEntityTranslation<Product, ProductTranslation>();
  const value = `${product.id},${price.period}`;
  return <option value={value}>
    {productTranslation.getCurrentTranslation(product)?.name}
  </option>
}

export function PriceModule({ price, taxRate, preffix }: { price: ProductPrice, taxRate: number, preffix?: string }) {
  const { t, i18n } = useTranslation();
  const net = price.price ?? 0;
  const tax = net * taxRate;
  const totalAmount = net + tax;
  if (net === 0) {
    return <></>;
  }
  if (price.period === LicensePeriod.Monthly) {
    return <>
      {preffix}<PriceValue price={{ ...price, price: totalAmount } as ProductPrice} /> {t('pricing.monthly')} {tax !== 0 && `(${t('pricing.taxIncluded', { amount: priceValue({ ...price, price: tax } as ProductPrice, i18n.resolvedLanguage) })})`}
    </>
  } else {
    return <>
      {preffix}<PriceValue price={{ ...price, price: totalAmount } as ProductPrice} /> {t('pricing.annually')} {tax !== 0 && `(${t('pricing.taxIncluded', { amount: priceValue({ ...price, price: tax } as ProductPrice, i18n.resolvedLanguage) })})`}
    </>
  }
}

enum ApplyStep {
  OwnerInfo,
  AppInfo,
  CenterDetails,
  Complete
}

export function ApplyForm() {
  const { t } = useTranslation();
  const { region } = useRegion();
  const apiConfiguration = useApiConfiguration();
  const [products, setProducts] = useState<Product[] | undefined>([]);
  const [modules, setModules] = useState<Product[] | undefined>([]);
  const productsApiClient = new ProductsClient(apiConfiguration);
  const tenantRequestsApiClient = new TenantRequestsClient(apiConfiguration);
  const [step, setStep] = useState(ApplyStep.OwnerInfo);
  const form = useForm({
    owner: {},
    center: {},
    app: {
      productId: undefined,
      period: undefined,
      additionalProducts: [] as string[]
    },
    content: '',
    referralCode: '',
  } as TenantApplyData);
  const applyFormTracking = useEventTracking('generate_lead', { currency: 'PLN', value: 200.00, source: "apply form" });
  const conversionTracking = useEventTracking('conversion', {
    'send_to': 'AW-16525429803/77QtCMaQktwZEKuY-Mc9',
    'value': 200.0,
    'currency': 'PLN'
  });

  useEffect(() => {
    if (_.has(form.validationData, 'app.productId') || _.has(form.validationData, 'app.subdomain')) {
      setStep(ApplyStep.AppInfo);
    }
  }, [ form.validationData ]);

  const normalizeSubdomain = (text?: string) =>
    // eslint-disable-next-line no-control-regex
    text?.replace(/[^\x00-\x7F]/g, "").toLowerCase().replace(' ', '-').replace('.', '-').replace('_', '-') ?? undefined;

  useEffect(() => {
    form.setData({
      ...form.data,
      app: {
        ...form.data.app,
        subdomain: normalizeSubdomain(form.data.app?.subdomain)
      }
    } as TenantApplyData);
  }, [form.data.app?.subdomain]);

  useEffect(() => {
    if (!products) return;
    form.setPending(true);
    productsApiClient
      .get(undefined, undefined, undefined, undefined, undefined, undefined)
      .then(data => {
        setProducts(data.items?.filter(p => p.isEnabled === true && p.key?.startsWith('plan-')));
        setModules(data.items?.filter(p => p.isEnabled === true && p.key?.startsWith('module-')));
      })
      .catch(form.catchAnyException)
      .finally(() => form.setPending(false));
  }, []);

  useEffect(() => {
    if (!region) return;
    if (form.data.center?.countryCode === undefined && form.data.business?.countryCode === undefined) {
      form.setData({
        ...form.data,
        center: { ...form.data.center, countryCode: region },
        business: { ...form.data.business, countryCode: region }
      } as TenantApplyData);
    } else if (form.data.center?.countryCode === undefined) {
      form.setData({
        ...form.data,
        center: { ...form.data.center, countryCode: region }
      } as TenantApplyData);
    } else if (form.data.business?.countryCode === undefined) {
      form.setData({
        ...form.data,
        business: { ...form.data.business, countryCode: region }
      } as TenantApplyData);
    }

  }, [region]);


  const onSubmit = () => {
    if (step !== ApplyStep.CenterDetails) {
      setStep(step + 1);
      return;
    }

    form.setPending(true);
    applyFormTracking();

    const tenantApplyRequest = { ...form.data } as TenantApplyData;

    if (tenantApplyRequest.business?.enabled !== true) {
      tenantApplyRequest.business = undefined;
    }

    if (tenantApplyRequest.center?.email === '') delete tenantApplyRequest.center.email;
    if (tenantApplyRequest.center?.phoneNumber === '') delete tenantApplyRequest.center.phoneNumber;
    if (tenantApplyRequest.business?.email === '') delete tenantApplyRequest.business.email;

    if (tenantApplyRequest.app) tenantApplyRequest.app.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    tenantRequestsApiClient.apply(tenantApplyRequest)
      .then(() => {
        setStep(ApplyStep.Complete);
        conversionTracking();
      })
      .catch((ex) => form.catchAnyException(ex))
      .finally(() => form.setPending(false));
  }

  return (
    <>
      <Progress steps={
        [
          { header: t('apply.steps.owner.header'), description: t('apply.steps.owner.description') },
          { header: t('apply.steps.app.header'), description: t('apply.steps.app.description') },
          { header: t('apply.steps.center.header'), description: t('apply.steps.center.description') },
        ]
      } current={step} />
      <hr className="my-8" />
      {step === ApplyStep.OwnerInfo && <ApplyFormOwnerInfo form={form} onSubmit={() => { setStep(ApplyStep.AppInfo); form.setPending(false); }} />}
      {step === ApplyStep.AppInfo && <ApplyFormAppInfo form={form} onSubmit={() => { setStep(ApplyStep.CenterDetails); form.setPending(false); }} onBack={() => setStep(ApplyStep.OwnerInfo)} products={products} modules={modules} />}
      {step === ApplyStep.CenterDetails && <ApplyFormCenterDetails form={form} onBack={() => setStep(ApplyStep.AppInfo)} onSubmit={onSubmit} />}
      {step === ApplyStep.Complete && <ApplyFormComplete />}

    </>
  )
}

function ApplyFormOwnerInfo({ form, onSubmit }: { form: IForm<TenantApplyData>, onSubmit: () => void }) {
  const { t } = useTranslation();

  return (
    <form onSubmit={e => form.onSubmit(e, onSubmit)}>
      <h4>{t('common.contactPersonData')}</h4>
      <div className="py-3 flex gap-x-3">
        <div className="flex-1">
          <TextField
            label={t('auth.fields.givenName')}
            {...form.input(
              'owner.givenName',
              'text', {
              placeholder: t('auth.fields.givenName'),
              autoComplete: 'given-name',
              required: true
            })}
          />
          <Feedback {...form.validation('owner.givenName')} />
        </div>
        <div className="flex-1">
          <TextField
            label={t('auth.fields.surname')}
            {...form.input(
              'owner.surname',
              'text', {
              placeholder: t('auth.fields.surname'),
              autoComplete: 'family-name',
              required: true
            })}
          />
          <Feedback {...form.validation('owner.surname')} />
        </div>
      </div>
      <div className="py-3">
        <TextField
          label={t('auth.fields.email')}
          {...form.input(
            'owner.email',
            'email', {
            placeholder: t('auth.fields.email'),
            autoComplete: 'email',
            required: true
          })}
        />
        <Feedback {...form.validation('owner.email')} />
      </div>
      <div className="py-3">
        <PhoneNumberField
          label={t('auth.fields.phoneNumber')}
          {...form.input(
            'owner.phoneNumber',
            'text', {
            placeholder: t('auth.fields.phoneNumber'),
            autoComplete: 'tel',
            required: true
          })}
        />
        <Feedback {...form.validation('owner.phoneNumber')} />
      </div>
      <div className="flex flex-row justify-between mt-4 gap-x-3">
        <LocalizedLink to="/">
          <Button type="button">
            {t('ui.back')}
          </Button>
        </LocalizedLink>
        <Button type="submit" color="primary" className="flex-grow">
          {t('ui.next')}
        </Button>
      </div>
    </form>
  )
}

function ApplyFormAppInfo({ form, onSubmit, onBack, products, modules }: {
  form: IForm<TenantApplyData>,
  onSubmit: () => void,
  onBack: () => void,
  products: Product[] | undefined,
  modules: Product[] | undefined
}) {
  const { t } = useTranslation();

  const taxRate = 0;

  return (
    <>
      <form onSubmit={e => form.onSubmit(e, onSubmit)}>
        <div className="py-3 flex flex-col gap-x-3">
          <div className="py-3">
            <TextField
              label={t('apply.message.fields.equestrianCenterName')}
              {...form.input(
                'center.name',
                'text', {
                placeholder: t('apply.message.fields.equestrianCenterName'),
                autoComplete: 'company',
                required: true
              })}
            />
            <Feedback {...form.validation('center.name')} />
          </div>
          <div className="py-3">
            <TextField
              label={t('apply.message.fields.subdomain')}
              suffix=".redini.app"
              {...form.input(
                'app.subdomain',
                'text', {
                placeholder: t('apply.message.fields.subdomain'),
                required: true
              })}
            />
            <Feedback {...form.validation('app.subdomain')} />
          </div>
          <div className="py-3 flex flex-col">
            <PricingSelect form={form} products={products} modules={modules} taxRate={taxRate} />
            <Feedback {...form.validation('app.productId')} />
            <Feedback {...form.validation('app.period')} />
          </div>
        </div>
        <div className="flex flex-row justify-between mt-4 gap-x-3">
          <Button type="button" onClick={onBack}>
            {t('ui.back')}
          </Button>
          <Button type="submit" color="primary" className="flex-grow">
            {t('ui.next')}
          </Button>
        </div>
      </form>
    </>
  );
}

function ApplyFormCenterDetails({ form, onSubmit, onBack }: { form: IForm<TenantApplyData>, onSubmit: () => void, onBack: () => void }) {
  const { t } = useTranslation();

  const populateCenterEmailFromOwner = () => form.setData({
    ...form.data,
    center: {
      ...form.data.center,
      email: form.data.owner?.email
    }
  } as TenantApplyData);
  const populateCenterPhoneNumberFromOwner = () => form.setData({
    ...form.data,
    center: {
      ...form.data.center,
      phoneNumber: form.data.owner?.phoneNumber
    }
  } as TenantApplyData);
  
  return (
    <>
      <form onSubmit={e => form.onSubmit(e, onSubmit)}>
        <h4>{t('apply.message.equestrianCenter')}</h4>
        <div className="py-3 flex flex-col gap-x-3">
          <div className="py-3">
            <TextField
              label={t('common.fields.address')}
              {...form.input(
                'center.address',
                'text', {
                placeholder: t('common.descriptions.address'),
                autoComplete: 'billing address-level1',
                required: true
              })}
            />
            <Feedback {...form.validation('center.address')} />
          </div>
          <div className="py-3 flex gap-x-3">
            <div className="flex-1">
              <TextField
                label={t('common.fields.postCode')}
                {...form.input(
                  'center.postCode',
                  'text', {
                  placeholder: t('common.fields.postCode'),
                  autoComplete: 'billing code',
                  required: true
                })}
              />
              <Feedback {...form.validation('center.postCode')} />
            </div>
            <div className="flex-1">
              <TextField
                label={t('common.fields.city')}
                className="flex-1"
                {...form.input(
                  'center.city',
                  'text', {
                  placeholder: t('common.fields.city'),
                  autoComplete: 'billing city',
                  required: true
                })}
              />
              <Feedback {...form.validation('center.city')} />
            </div>
          </div>
          <div className="py-3">
            <SelectField
              label={t('common.fields.country')}
              {...form.select(
                'center.countryCode', {
                placeholder: t('common.fields.country'),
                required: true
              })}
            >
              <option value="undefined">{t('ui.select.pleaseSelect')}</option>
              {EnabledRegions.map(c => <option key={c.countryCode} value={c.countryCode}><CountryName countryCode={c.countryCode} /></option>)}
            </SelectField>
            <Feedback {...form.validation('center.countryCode')} />
          </div>
          <div className="py-3">
            <TextField
              label={t('auth.fields.email')}
              {...form.input(
                'center.email',
                'email', {
                placeholder: t('auth.fields.email'),
                autoComplete: 'email'
              })}
            />
            <Feedback {...form.validation('center.email')} />
            {form.data.owner?.email && (
              <div
                className="text-xs font-light text-primary-700 cursor-pointer text-end pt-3"
                onClick={populateCenterEmailFromOwner}
              >
                {t('apply.thisSameAsContactPerson')}
              </div>)}
          </div>
          <div className="py-3">
            <PhoneNumberField
              label={t('auth.fields.phoneNumber')}
              {...form.input(
                'center.phoneNumber',
                'text', {
                placeholder: t('auth.fields.phoneNumber'),
                autoComplete: 'tel'
              })}
            />
            <Feedback {...form.validation('center.phoneNumber')} />
            {form.data.owner?.phoneNumber && (
              <div
                className="text-xs font-light text-primary-700 cursor-pointer text-end pt-3"
                onClick={populateCenterPhoneNumberFromOwner}
              >
                {t('apply.thisSamePhoneNumberAsContactPerson')}
              </div>)}
          </div>
          <div className="py-3">
            <TextField
              label={t('common.fields.referralCode')}
              {...form.input(
                'referralCode',
                'text', {
                placeholder: t('common.fields.referralCode')
              })}
            />
            <Feedback {...form.validation('referralCode')} />
          </div>
          <div className="py-3">
            <TextAreaField
              label={t('common.fields.notes')}
              {...form.textArea(
                'content',
                'text', {
                placeholder: t('common.fields.notes')
              })}
              rows={4}
            />
            <Feedback {...form.validation('referralCode')} />
          </div>
        </div>
        <div className="flex flex-row justify-between mt-4 gap-x-3">
          <Button type="button" onClick={onBack}>
            {t('ui.back')}
          </Button>
          <Button type="submit" color="primary" disabled={form.pending} className="flex-grow">
            {form.pending && <Spinner className="size-4 mr-3" />}
            {t('ui.next')}
          </Button>
        </div>
      </form>
    </>
  );
}
function ApplyFormComplete() {
  const { t } = useTranslation();

  return (
    <>
      <div className="text-center">
        <h3 className="font-medium text-xl lg:text-3xl mb-4">{t('apply.message.thankYou')}</h3>
        <p className="text-lg">{t('apply.message.weWillContactYou')}</p>
      </div>
      <div className="text-center py-8 lg:py-16">
        <FontAwesomeIcon icon={faCheckCircle} className="text-secondary-500 opacity-20 text-8xl lg:text-9xl mx-auto" />
      </div>
      <div className="flex flex-row justify-center">
        <LocalizedLink to="/" className="flex-grow">
          <Button type="button" color="primary" className="w-full">
            {t('common.home')}
            </Button>
        </LocalizedLink>
      </div>
    </>
  )
}

function PricingSelect({
  form, products, modules, taxRate
}: {
  form: IForm<TenantApplyData>,
  products: Product[] | undefined,
  modules?: Product[] | undefined,
  taxRate: number,
}) {
  const { t } = useTranslation();
  const { region } = useRegion();
  const currency = getCurrencyByRegion(region);
  const productTranslation = useEntityTranslation<Product, ProductTranslation>();

  const value = useMemo(
    () => [form.data.app?.productId, form.data.app?.period].join(','),
    [form.data.app?.productId, form.data.app?.period]
  );

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "null") {
      form.setData({
        ...form.data,
        app: {
          ...form.data.app,
          productId: undefined,
          period: undefined
        }
      } as TenantApplyData);
      return;
    }
    const [productId, period] = e.target.value.split(',');
    form.setData({
      ...form.data,
      app: {
        ...form.data.app,
        productId,
        period: parseInt(period)
      }
    } as TenantApplyData);
  }

  if (products === undefined) return <Spinner />;

  return (
    <>
      <div className="flex flex-col gap-y-3">
        <SelectField
          label={t('apply.message.fields.tier')}
          onChange={onChange}
          value={value}
          required
        >
          <option value="null">{t('ui.select.pleaseSelect')}</option>
          {products
            .filter(p => p.prices && p.prices.length > 0)
            .sort((a, b) => (_.min(a.prices?.filter(p => p.currencyCode === currency).map(p => p.price)) ?? 0) - (_.min(b.prices?.filter(p => p.currencyCode === currency).map(p => p.price)) ?? 0))
            .map(product => product.prices?.filter(price => price.currencyCode === currency && price.period === LicensePeriod.Monthly).map(price => <PricePlanOption product={product} price={price} taxRate={taxRate} />))
            .flat()}
          {products.filter(p => !p.prices || p.prices.length === 0).map(p => <option value={p.key}>{t('apply.planIndividual')}</option>)}
        </SelectField>
        <FormCheckbox.Set title="Modules">
          {modules
            ?.filter(p => p.prices && p.prices.length > 0)
            .sort((a, b) => (_.min(a.prices?.filter(p => p.currencyCode === currency).map(p => p.price)) ?? 0) - (_.min(b.prices?.filter(p => p.currencyCode === currency).map(p => p.price)) ?? 0))
            .map(product =>
              product.prices
                ?.filter(price => price.currencyCode === currency && price.period === form.data.app?.period)
                .map(price => (
                  <div key={price.id} className="flex flex-row justify-between">
                    <FormCheckbox.Input {...form.input(`app.additionalProducts[]`, 'checkbox', { value: product.id, placeholder: productTranslation.getCurrentTranslation(product)?.name })} />
                  </div>
                )))
            .flat()}
        </FormCheckbox.Set>
      </div>
    </>
  )
}

interface Step {
  header: string;
  description: string;
}

export default function Progress({ steps, current }: { steps: Step[], current: number }) {
  return (
    <nav aria-label="Progress">
      <ol role="list" className="space-y-4 md:flex md:space-x-8 md:space-y-0">
        {steps.map((step, i) => (
          <li key={i} className="md:flex-1">
            {i < current ? (
              <div
                className="group flex flex-col border-l-4 border-primary-700 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
              >
                <span className="text-sm font-medium text-primary-700">{step.header}</span>
                <span className="text-sm font-medium">{step.description}</span>
              </div>
            ) : i === current ? (
              <div
                aria-current="step"
                className="flex flex-col border-l-4 border-secondary-500 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
              >
                  <span className="text-sm font-medium text-secondary-500">{step.header}</span>
                <span className="text-sm font-medium">{step.description}</span>
              </div>
            ) : (
              <div
                className="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
              >
                <span className="text-sm font-medium text-gray-500 group-hover:text-gray-700">{step.header}</span>
                <span className="text-sm font-medium">{step.description}</span>
              </div>
            )}
          </li>
        ))}
      </ol>
    </nav>
  )
}