import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { PaymentRequestsClient, PaymentRequest } from "src/api/financial/Accountancy";
import { HttpQueryFilter, PaymentMethod, PaymentMethodsClient, PaymentMethodTranslation, PaymentsClient, PaymentSubMethod, RequestPayment } from "src/api/financial/Payments";
import { Product, ProductsClient, ProductTranslation, Subscription, SubscriptionsClient } from "src/api/licensing/Licensing";
import { Button } from "src/components/Button";
import ContentParse from "src/components/Cms/ContentParse";
import Spinner from "src/components/Feedback/Spinner";
import GridSelect, { GridSelectOption } from "src/components/Form/GridSelect";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import useEntityTranslation from "src/hooks/useEntityTranslation";
import { Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe, StripeElementLocale } from "@stripe/stripe-js";
import Alert from "src/components/Feedback/Alert";
import BillingInfoView from "../User/BillingInfo";
import configuration from "src/config/config";

interface StripeEndpointResponse {
  paymentIntentSecret: string;
  customerId: string;
  ephemeralKey: string;
}

const SubscriptionPaymentView = () => {
  const [subscription, setSubscription] = useState<Subscription | undefined>();
  const [product, setProduct] = useState<Product | undefined>();
  const { t } = useTranslation();
  const { id, automaticPayment } = useParams();
  const apiConfiguration = useApiConfiguration();
  const subscriptionsClient = new SubscriptionsClient(apiConfiguration);
  const productsClient = new ProductsClient(apiConfiguration);
  const productTranslation = useEntityTranslation<Product, ProductTranslation>();

  useEffect(() => {
    if (subscription?.id === id) return;
    fetchSubscription();
  }, [subscription, id]);

  useEffect(() => {
    fetchProduct();
  }, [subscription?.productId]);

  const fetchSubscription = () => {
    if (!id) return;
    subscriptionsClient
      .find(id)
      .then(response => setSubscription(response))
      .catch(console.error);
  }

  const fetchProduct = () => {
    if (!subscription?.productId) return;
    productsClient
      .find(subscription?.productId)
      .then(response => setProduct(response))
      .catch(console.error);
  }

  return (
    <PaymentView
      title={product ? `${t('licensing.subscription')} ${productTranslation.getCurrentTranslation(product)?.name}` : undefined}
      externalTags={subscription ? `SubscriptionId=${subscription.id}` : undefined}
      offSession={automaticPayment === 'true'}
      billingInfoForm={true}
    />
  );

}

interface PaymentViewProps {
  title?: string;
  externalId?: string;
  externalTags?: string;
  paymentMethodId?: string;
  setup?: boolean;
  offSession?: boolean;
  billingInfoForm?: boolean;
}

interface PaymentProps {
  paymentMethod: PaymentMethod;
  paymentRequest: PaymentRequest;
  setup?: boolean;
  offSession?: boolean;
  billingInfoForm?: boolean;
}

const PaymentView = ({ externalId, externalTags, paymentMethodId, title, billingInfoForm, ...otherProps }: PaymentViewProps) => {
  const { t, i18n } = useTranslation();
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | undefined>();
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | undefined>();

  const apiConfiguration = useApiConfiguration();
  const paymentMethodsClient = new PaymentMethodsClient(apiConfiguration);
  const paymentRequestsClient = new PaymentRequestsClient(apiConfiguration);

  const paymentMethodTranslation = useEntityTranslation<PaymentMethod, PaymentMethodTranslation>(); 

  useEffect(() => {
    if (!paymentMethod) {
      fetchPaymentMethod();
      return;
    }
  }, [paymentMethod]);

  useEffect(() => {
    if (!externalId && !externalTags) return;
    const filters: HttpQueryFilter[] = [];
    if (externalId) {
      filters.push({ property: "ExternalId", value: externalId, type: '=' } as HttpQueryFilter);
    }
    if (externalTags) {
      filters.push({ property: "ExternalTags", value: externalTags, type: '%' } as HttpQueryFilter);
    }
    paymentRequestsClient
      .get(filters, undefined, undefined, undefined, undefined, undefined)
      .then(response => setPaymentRequest(response.items?.[0]))
      .catch(error => console.error(error))
  }, [externalId, externalTags]);

  const fetchPaymentMethod = () => {
    const filters: HttpQueryFilter[] = [];
    if (paymentMethodId !== undefined) {
      filters.push({ property: 'id', value: paymentMethodId, type: '=' } as HttpQueryFilter);
    } else {
      filters.push({ property: 'Provider', value: 'Stripe', type: '=' } as HttpQueryFilter);
    }
    paymentMethodsClient
      .get(filters, undefined, undefined, undefined, undefined, undefined)
      .then(response => setPaymentMethod(response.items?.[0]))
      .catch(console.error)
  }

  return (
    <div className="bg-slate-50">
      <div className="max-w-sm md:max-w-xl lg:max-w-3xl xl:max-w-7xl mx-auto px-4 md:px-0">
        <div className="flex flex-col lg:flex-row">
          <div className="pt-20 lg:pt-32"> 
              <h2 className="text-4xl text-gray-500 mb-4">{t('payment.payment')}</h2>
              <h2 className="text-2xl text-gray-500 mb-4">{paymentMethodTranslation.getCurrentTranslation(paymentMethod)?.title}</h2>
              <h2 className="text-2xl text-gray-500 mb-4">{title}</h2>
              <h2 className="text-2xl text-gray-500 mb-4">{t('payment.amount')}: {paymentRequest?.value?.toLocaleString(i18n.resolvedLanguage, { style: 'currency', currency: paymentRequest?.currencyCode ?? 'PLN' })}</h2>
          </div>
          <div className="flex-1 bg-white">
            <svg
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
              className="hidden size-full fill-slate-50 lg:block"
            >
              <polygon points="0,0 0,0 100,100 0,100" />
            </svg>
          </div>
          <div className="bg-white px-8 min-w-96 lg:pt-32">
            {!paymentRequest && <Spinner />}
            {paymentMethod && paymentRequest && <PaymentForm paymentMethod={paymentMethod} paymentRequest={paymentRequest} {...otherProps} />}
          </div>
          <div className="flex-1 bg-slate-50">
            <svg
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
              className="hidden size-full fill-white lg:block"
            >
              <polygon points="0,0 0,0 100,100 0,100" />
            </svg>
          </div>
          {billingInfoForm && (
            <div className="lg:pt-24 lg:w-1/3">
              <BillingInfoView /> 
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const PaymentForm = (props: PaymentProps) => {
  switch (props.paymentMethod?.provider) {
    case 'Stripe':
      return <StripePayment {...props} />;
    case 'Przelewy24':
      return <Przelewy24Payment  {...props} />;
    default:
      return <Spinner />
  }
}

const StripePayment = (props: PaymentProps) => {
  const { paymentRequest, paymentMethod, offSession } = props;
  const { i18n } = useTranslation();
  const [secret, setSecret] = useState<string | undefined>(undefined);

  const apiConfiguration = useApiConfiguration();
  const paymentsClient = new PaymentsClient(apiConfiguration);

  useEffect(() => {
    getPaymentClientSecret();
  }, [paymentMethod]);

  const getPaymentClientSecret = async () => {
    const response = await paymentsClient
      .requestPaymentPOST(
        paymentRequest.id!,
        paymentMethod.id!, {
          offSession: offSession
        } as RequestPayment)
    const responseObj = JSON.parse(response) as StripeEndpointResponse;
    setSecret(responseObj.paymentIntentSecret);
    return responseObj;
  }

  const stripePublishableKey = configuration.integrations?.stripePublishableKey;

  if(!stripePublishableKey) return <>err</>;

  const stripePromise = loadStripe(
    stripePublishableKey,
    {
      locale: i18n.resolvedLanguage as StripeElementLocale ?? "en"
    }
  );

  if (!secret) return <Spinner className="mx-auto h-8" />;
  
  return (
    <>
      <Elements stripe={stripePromise} options={{ clientSecret: secret }}>
        <StripeCheckoutForm {...props} />
      </Elements>
    </>
  );
}

function StripeCheckoutForm(props: PaymentProps) {
  const { paymentRequest } = props;
  const { i18n, t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string | undefined>(undefined);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    setMessage(undefined);
    setLoading(true);
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/${i18n.resolvedLanguage}/payments/return`,
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }
    setLoading(false);
  }

  return (
    <form onSubmit={(e) => onSubmit(e)}>
      {message && <Alert.Error title={message} />}
      <PaymentElement options={
        {
          layout: 'accordion',
          defaultValues: {
            billingDetails: {
              name: paymentRequest.user?.fullName,
              email: paymentRequest.user?.email,
              phone: paymentRequest.user?.phoneNumber,

            }
          }
        }
      } />
      <div className="flex flex-row justify-end">
        <Button color="primary" className="px-5 py-3 mt-8">
          {t('payment.do')}
          {loading && <Spinner className="ml-3 h-4" />}
        </Button>
      </div>
    </form>
  );
}


const Przelewy24Payment = (props: PaymentProps) => {
  const { paymentMethod, paymentRequest } = props;
  const title = 'Test';
  const { t, i18n } = useTranslation();
  const [subMethods, setSubMethods] = useState<PaymentSubMethod[]>([]);
  const [subMethod, setSubMethod] = useState<PaymentSubMethod | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);

  const apiConfiguration = useApiConfiguration();
  const paymentMethodsClient = new PaymentMethodsClient(apiConfiguration);
  const paymentsClient = new PaymentsClient(apiConfiguration);
  const entityTranslation = useEntityTranslation<PaymentMethod, PaymentMethodTranslation>();

  useEffect(() => {
    if (paymentMethod.haveSubMethods) {
      setLoading(true);
      paymentMethodsClient
        .listSubMethods(paymentMethod.id!, 100, 'PLN')
        .then(response => setSubMethods(response ?? []))
        .catch(error => console.error(error))
        .finally(() => setLoading(false));
    }
  }, [paymentMethod]);


  const onClickPayment = () => {
    if (!paymentRequest || !paymentMethod) return;
    setPaymentLoading(true);
    paymentsClient
      .requestPaymentPOST(
        paymentRequest.id!,
        paymentMethod.id!, {
          subMethodId: subMethod?.id,
        } as RequestPayment)
      .then(response => {
        window.location.href = response;
        return;
      })
      .catch(error => console.error(error))
      .finally(() => setPaymentLoading(false));
  }

  const options = subMethods.map(m => ({
    id: m.id,
    value: m.id,
    label: m.name,
    description: m.description,
    image: m.imageUrl
  }) as GridSelectOption);

  return (
    <>
      <div className="max-w-sm md:max-w-xl lg:max-w-3xl xl:max-w-7xl mx-auto px-4 md:px-0 mb-8">
        <div className="lg:flex lg:justify-between">
          <div className="lg:w-5/12 lg:pr-3">
            <h2 className="text-4xl text-gray-500 mb-4">{t('payment.payment')}</h2>
            <h2 className="text-2xl text-gray-500 mb-4">{entityTranslation.getCurrentTranslation(paymentMethod)?.title}</h2>
            <h2 className="text-2xl text-gray-500 mb-4">{title}</h2>
            <h2 className="text-2xl text-gray-500 mb-4">{t('payment.amount')}: {paymentRequest?.value?.toLocaleString(i18n.resolvedLanguage, { style: 'currency', currency: paymentRequest?.currencyCode ?? 'PLN' })}</h2>
          </div>
          <div className="lg:w-7/12">
            {loading && <Spinner className="mx-auto h-8" />}
            {paymentMethod?.haveSubMethods &&
              <div className="h-96 overflow-y-scroll">
                <GridSelect value={paymentMethod?.id} options={options} onChange={(e) => setSubMethod(subMethods.find(i => i.id == e.target.value))} small />
              </div>
            }
            <div className="flex flex-row justify-end">
              <Button color="primary" className="px-5 py-3 mt-8" onClick={onClickPayment} disabled={loading || (paymentMethod?.haveSubMethods && subMethod === undefined)}>
                {t('payment.do')}
                {paymentLoading && <Spinner className="ml-3 h-4" />}
              </Button>
              {entityTranslation.getCurrentTranslation(paymentMethod)?.agreements && <div className="text-sm text-gray-400 mt-8"><ContentParse>{entityTranslation.getCurrentTranslation(paymentMethod)?.agreements}</ContentParse></div>}
              <div className="text-sm text-gray-400 mt-8">{t('payment.redirect')}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SubscriptionPaymentView;