import { useEffect, useState } from "react";
import { HttpQueryFilter, License, LicensesClient } from "src/api/licensing/Licensing";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import useUser from "src/hooks/useUser";
import Alert from "../Feedback/Alert";
import Spinner from "../Feedback/Spinner";
import { useTranslation } from "react-i18next";

interface LicenseSelectProps {
  onSelect: (license?: License) => void;
  selectedLicense?: License;
}

const LicenseSelect = (props: LicenseSelectProps) => {
  const { selectedLicense, onSelect } = props;
  const { t } = useTranslation();
  const [licenses, setLicenses] = useState<License[] | undefined>();
  const apiConfiguration = useApiConfiguration();
  const user = useUser();
  const licensesClient = new LicensesClient(apiConfiguration);

  useEffect(() => {
    if (!user?.id) return;
    fetchLicenses();
  }, [user?.id]);

  useEffect(() => {
    if (!selectedLicense) {
      onSelect(licenses?.[0]);
    }
  }, [licenses]);

  const fetchLicenses = () => {
    licensesClient
      .get([{ property: 'userId', value: user?.id, type: '=' }] as HttpQueryFilter[], undefined, undefined, undefined, undefined, undefined)
      .then(response => setLicenses(response.items))
      .catch(response => console.error(response));
  }

  if (licenses === undefined) {
    return <Spinner />;
  }

  if(licenses !== undefined && licenses.length === 0) {
    return (<Alert.Error title={t('licensing.noLicense')} noClose />);
  }

  return (
    <>
      <div className="flex gap-x-8">
        <h1 className="text-3xl text-gray-700">{t('licensing.item')}</h1>
        <select className="flex-1" onChange={e => onSelect(licenses?.find(l => l.id === e.target.value))} value={selectedLicense?.id}>
          {licenses?.map(license => <option value={license.id}>{license.name}</option>)}
        </select>
      </div>
    </>
  )
}

export default LicenseSelect;