import { useRegion } from "src/hooks/useRegion"


export default function PhoneNumber({ phoneCode, phoneNumber }: {
  phoneCode: number,
  phoneNumber: number | string
}) {
  const { data } = useRegion();
  return data?.phoneCode === phoneCode ? String(phoneNumber) : `+${phoneCode} ${phoneNumber}`;

}