import { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import Tabs, { Tab } from 'src/components/Tabs/Pills';
import { useTranslation } from 'react-i18next';
import { Header } from 'src/components/Header';
import { Footer } from 'src/components/Footer';
import useUser from 'src/hooks/useUser';
import useAuthGuard from 'src/hooks/useAuthGuard';
import { Helmet } from 'react-helmet';


const UserIndex = () => {
  const { t, i18n } = useTranslation();
  const { tab } = useParams();
  const navigate = useNavigate();
  const _authGuard = useAuthGuard('/');
  const user = useUser();
  const tabs: Tab[] = [
    { id: "my-account", name: t('crm.headers.myAccount'), },
    { id: "subscription", name: t('crm.headers.subscription'), },
    { id: "invoices", name: t('crm.headers.invoices') },
    { id: "billing-info", name: t('crm.headers.billingInfo') },
    { id: "payment-data", name: t('crm.headers.paymentData') },
    { id: "payment", name: t('crm.headers.payment'), visible: false },
    { id: "tickets", name: t('crm.headers.tickets'), visible: false },
  ];

  const [currentTab, setCurrentTab] = useState(tab ?? "my-account");

  useEffect(() => {
    if (!tab) return;
    setCurrentTab(tab);
  }, [tab]);

  useEffect(() => {
    navigate(`/${i18n.resolvedLanguage}/user/${currentTab}`);
  }, [currentTab]);

  return (
    <>
      <Helmet>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Header />
      <main>
        <div className="mx-5 lg:mx-10 xl:mx-auto xl:max-w-6xl">
          <h1 className="text-3xl font-light text-slate-500 mt-24 mb-8">{t('ui.hello')}, {user?.givenName}</h1>
          <header className="mb-8">
            <Tabs tabs={tabs} currentTab={currentTab} setCurrentTab={setCurrentTab} />
          </header>
          <Outlet />
        </div>
      </main>
      <Footer />
    </>
  )
}

export default UserIndex;