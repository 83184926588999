import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { ClientNewsfeedCategoriesClient, ClientNewsfeedPostsClient, Content, ContentTranslation, HttpQueryFilter, HttpQueryOrder, ProblemDetails } from "src/api/cms/Cms";
import Spinner from "src/components/Feedback/Spinner";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import useEntityTranslation from "src/hooks/useEntityTranslation";

import useApplicationDispatch from "src/hooks/useApplicationDispatch";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IndexResult } from "src/api/Interfaces";
import { setBreadcrumbs } from "src/store/application/actions";
import Pagination from "src/components/Table/Pagination";
import { QueryOrderDirection } from "src/api/Base";
import LocalizedLink from "src/components/Router/LocalizedLink";
import useLocalizedNavigate from "src/hooks/useNavigate";

import configuration, { ConfigurationApis } from "src/config/config";
import useTenant from "src/hooks/useTenant";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import { MetaHead } from "src/components/MetaHead";

export interface PostElementComponentProps {
  post: Content;
}

export const PostRow = (props: PostElementComponentProps) => {
  const { post } = props;
  const { t, i18n } = useTranslation();
  const tenant = useTenant();
  const entityTranslation = useEntityTranslation<Content, ContentTranslation>();
  const translation = entityTranslation.getCurrentTranslation(post);
  const plainText = translation?.content?.replace(/<[^>]+>/g, '') ?? "";

  const apiConfiguration = useApiConfiguration();
  const apiClient = new ClientNewsfeedPostsClient(apiConfiguration);

  const [loading, setLoading] = useState(false);
  const [thumbnail, setThumbnail] = useState<Content | undefined>();

  useEffect(() => {
    setLoading(true);
    apiClient.getPhotos(
      post.id!,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined
    )
      .then(response => setThumbnail(response.items?.find(t => t)))
      .catch(console.error)
      .finally(() => setLoading(false));
  }, []);

  const thumbnailUrl = thumbnail ? `${configuration.api[ConfigurationApis.Content]}/api/v1/newsfeed/photos/${thumbnail.id}/download?XTenantId=${tenant}` : '/img/default-image.webp';

  return (
    <LocalizedLink to={`/n/p/${translation?.url}`}>
      <article key={post.id} className="relative isolate flex flex-col gap-8 lg:flex-row mb-8">
        <div className="relative aspect-[16/9] sm:aspect-[2/1] lg:aspect-square lg:w-64 lg:shrink-0">
          {loading && <Spinner className="h-4 mx-auto" />}
          {!loading && <img
            src={thumbnailUrl}
            alt={thumbnail?.name || t('cms.defaultImage')}
            title={thumbnail?.name || t('cms.defaultImage')}
            className="absolute inset-0 h-full w-full rounded-2xl bg-gray-50 object-cover max-h-48"
          />}
        </div>
        <div>
          <div className="flex items-center gap-x-4 text-xs">
            <time dateTime={post.created?.toLocaleString()} className="text-gray-500">
              {post.created?.toLocaleString(i18n.resolvedLanguage, { dateStyle: 'short', timeStyle: 'short' })}
            </time>
          </div>
          <div className="group relative max-w-4xl">
            <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
              <span className="absolute inset-0" />
              {translation?.title}
            </h3>
            <p className="mt-5 text-sm leading-6 text-gray-600 max-h-24 overflow-ellipsis overflow-hidden justify-center">{plainText}</p>
          </div>
          <div className="mt-6 flex border-t border-gray-900/5 pt-6">
            <div className="relative flex items-center gap-x-4">
              <div className="text-sm leading-6">
                <p className="text-gray-600">
                  <FontAwesomeIcon icon={faTag} className="inline h-3 mb-1 mr-1" /> {translation?.meta?.keywords}
                </p>
              </div>
            </div>
          </div>
        </div>
      </article>
    </LocalizedLink>
  );
}

const Newsfeed = () => {
  const { url } = useParams<string>();
  const { t } = useTranslation();
  const apiConfiguration = useApiConfiguration();
  const apiClient = new ClientNewsfeedPostsClient(apiConfiguration);
  const categoriesClient = new ClientNewsfeedCategoriesClient(apiConfiguration);
  const entityTranslation = useEntityTranslation<Content, ContentTranslation>();
  const applicationDispatch = useApplicationDispatch();
  const navigate = useLocalizedNavigate();

  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState<IndexResult<Content>>();
  const [category, setCategory] = useState<Content>();
  const [page, setPage] = useState(1);
  const pageSizes = [10, 15, 20, 25, 30];
  const [pageSize, setPageSize] = useState(pageSizes[0]);

  const categoryTranslation = entityTranslation.getCurrentTranslation(category);

  const onError = (e: ProblemDetails) => {
    switch (e.status) {
      case 404:
        navigate('/404');
        break;
      default:
        console.error(e);
        break;
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    if (!url) {
      if (category) {
        setCategory(undefined);
      }
      return;
    }
    setLoading(true);
    categoriesClient.findByUrl(url || "")
      .then(response => setCategory(response))
      .catch(onError)
      .finally(() => setLoading(false));
  }, [url]);

  useEffect(() => {
    if (url && !category) {
      return;
    }
    setLoading(true);
    const filters: HttpQueryFilter[] = [{ property: "isVisible", value: "true", type: "=" } as HttpQueryFilter];
    if (category) {
      filters.push({ property: "parentId", value: category.id, type: "=" } as HttpQueryFilter);
    }
    apiClient.get(filters, [{ property: "created", direction: QueryOrderDirection.DESC } as HttpQueryOrder], undefined, undefined, page, 10)
      .then(response => setPosts(response))
      .catch(onError)
      .finally(() => setLoading(false));
  }, [category, page, pageSize]);

  useEffect(() => {
    const breadcrumbs = [{ label: 'cms.newsfeed.posts.group', href: `/n` }];
    if (category) {
      breadcrumbs.push({ label: categoryTranslation?.meta?.title || "Untitled", href: `/n/c/${categoryTranslation?.url ?? category?.id}` });
    }
    applicationDispatch(setBreadcrumbs(breadcrumbs));
  }, [category]);

  return (
    <>
      <MetaHead title="Aktualności" description="Aktualności" />

      <div className="px-3 md:px-0 max-w-sm md:max-w-xl lg:max-w-3xl xl:max-w-7xl mx-auto pt-20 lg:pt-24">
        <div className="border-b border-gray-200 pb-5 mt-12 mb-5 flex justify-between">
          <h3 className="text-2xl font-semibold leading-6 text-gray-900">{t('cms.newsfeed.header')}</h3>
          <h3 className="text-2xl font leading-6 text-gray-300">{category ? categoryTranslation?.title : t('cms.newsfeed.allPosts')}</h3>
        </div>
        <div className="my-8">
          {loading && <Spinner className="h-16 mx-auto" />}
          <div>
            {!loading && posts?.items?.map(post => <PostRow post={post} />)}
          </div>
          {!loading && !posts?.items?.length && <p className="text-center my-12 text-gray-400">{t('cms.newsfeed.empty')}</p>}
        </div>
        <Pagination
          page={page}
          pageSize={pageSize}
          pageSizes={pageSizes}
          total={posts?.totalCount || 0}
          onSetPage={setPage}
          onSetPageSize={setPageSize}
          nextButton={posts?.hasNextPage || false}
          previousButton={posts?.hasPreviousPage || false}
          distance={3}
        />
      </div >
    </>
  )
}

export default Newsfeed;