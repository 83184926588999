import RegionsData from './Regions.json';

export interface Region {
  countryCode: string;
  currency: string;
  category: string;
  phoneCode: number;
  enabled?: boolean;
}

export const FALLBACK_REGION = 'pl';

export const Regions = RegionsData as Region[];
export const EnabledRegions = Regions.filter((r) => r.enabled);

export const detectRegionByGeoIp = async (): Promise<string | undefined> => {
  try {
    const response = await fetch("https://ipinfo.io/json?token=ab85cc3e483da4"); // Requires API key
    const data = await response.json();
    const countryCode = data.country ? String(data.country).toLowerCase() : undefined;
    if (!countryCode) return;
    if (EnabledRegions.some((r) => r.countryCode === countryCode)) {
      return countryCode;
    }
    return;
  } catch (error) {
    console.error("GeoIP lookup failed:", error);
    return;
  }
};

export const detectRegion = (): string => {
  try {
    const locale = new Intl.Locale(navigator.language);
    const region = locale.region ?? '';
    if (EnabledRegions.some((r) => r.countryCode === region)) {
      return region;
    }
    return 'pl';
  } catch {
    return FALLBACK_REGION;
  }
};