import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/Button'
import LocalizedLink from 'src/components/Router/LocalizedLink'
import SlimLayoutComponent from 'src/layouts/SlimLayoutComponent';

export default function Error() {
  const { t } = useTranslation();
  useEffect(() => { window.scrollTo(0, 0) }, []);

  return (
    <SlimLayoutComponent>
      <div className="flex">
        <LocalizedLink to="/" aria-label="Home" className="flex">
          <img src="/images/logos/logo-full-color-transparent.webp" className="relative h-12 w-auto" alt="Redini Logo" />
        </LocalizedLink>
      </div>
      <p className="mt-20 text-sm font-medium text-gray-700">{t('common.errors.error')}</p>
      <h1 className="mt-3 text-lg font-semibold text-gray-900">
        {t('common.errors.unknown')}
      </h1>
      <Button to="/" className="mt-10">
        {t('common.errors.actions.goBackHome')}
      </Button>
    </SlimLayoutComponent>
  )
}
