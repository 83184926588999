import moment from 'moment';
import _ from 'lodash';
import { Button } from 'src/components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/pro-duotone-svg-icons';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import Tabs, { Tab } from 'src/components/Tabs/Pills';
import { HttpQueryFilter, Invoice, InvoicesClient } from 'src/api/financial/Accountancy';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import useUser from 'src/hooks/useUser';
import useLocalizedNavigate from 'src/hooks/useNavigate';

interface InvoiceRowProps {
  invoice: Invoice;
  selected?: boolean;
  onSelect: (selected: boolean) => void;
  onClickDownload: () => void;
  onClickPayment: () => void;
}

const InvoiceRow = (props: InvoiceRowProps) => {
  const { invoice, selected, onSelect, onClickDownload, onClickPayment } = props;
  const { t, i18n } = useTranslation();
  const toPaid = invoice.valueRemain?.gross ?? 0;
  const isPaid = toPaid <= 0;

  return (
    <tr>
      <td className="p-3">{false && <input type="checkbox" checked={selected} onChange={(e) => onSelect(e.target.checked)} />}</td>
      <td className="text-start p-3">{invoice.number}</td>
      <td className="text-end p-3">{invoice.value?.gross?.toLocaleString(i18n.resolvedLanguage, { style: 'currency', currency: 'PLN' })}</td>
      <td className="text-end p-3">{toPaid.toLocaleString(i18n.resolvedLanguage, { style: 'currency', currency: 'PLN' })}</td>
      <td className="text-end p-3">{moment(invoice.issueDate).format('DD.MM.YYYY')}</td>
      <td className="text-end p-3">{moment(invoice.paymentTerm).format('DD.MM.YYYY')}</td>
      <td className="text-end p-3">{isPaid ? moment(invoice.paymentDate).format('DD.MM.YYYY') : 'Brak'}</td>
      <td className="text-start p-3 flex gap-x-3">
        <Button color="primary" onClick={onClickDownload}>
          <FontAwesomeIcon icon={faDownload} />
        </Button>
        {!isPaid && <Button color="secondary" className="flex-grow" onClick={onClickPayment}>
          {t('payment.do')}
        </Button>}
      </td>
    </tr>
  )
}


const InvoicesView = () => {
  const [selected, setSelected] = useState<string[]>([]);
  const { t } = useTranslation();
  const user = useUser();
  const navigate = useLocalizedNavigate();

  const apiConfiguration = useApiConfiguration();
  const invoicesClient = new InvoicesClient(apiConfiguration);
  const [invoices, setInvoices] = useState<Invoice[]>([]);

  const fetch = () => {
    invoicesClient
      .get([{ property: 'buyer.userId', value: user?.id, type: '=' }] as HttpQueryFilter[], undefined, undefined, undefined, undefined, undefined)
      .then(response => setInvoices(response.items ?? []))
      .catch(response => console.error(response));
  }

  const download = (invoice: Invoice) => {
    invoicesClient
      .download(invoice.id!)
      .then(response => {
        const url = window.URL.createObjectURL(response.data);
        const tempLink = document.createElement('a');
        tempLink.href = url;
        tempLink.setAttribute('download', `${invoice.number || invoice.id}.pdf`);
        tempLink.click();
      })
      .catch(console.error)
  }

  const payment = (invoice: Invoice) => {
    navigate(`/payments/invoice/${invoice.id!}`);
  }

  useEffect(() => {
    if (!user?.id) return;
    fetch();
  }, [user?.id]);

  const toggleSelect = (id: string, value: boolean) => {
    if (value) {
      setSelected([...selected, id]);
    } else {
      setSelected(selected.filter(i => i !== id));
    }
  }

  const tabs: Tab[] = [
    { id: "unpaid", name: t('invoices.unpaid'), },
    { id: "paid", name: t('invoices.paid'), }
  ];

  const [currentTab, setCurrentTab] = useState("unpaid");

  return (
    <>
      <Tabs tabs={tabs} currentTab={currentTab} setCurrentTab={setCurrentTab} secondary />
      <div className="py-5">
        <h1 className="text-4xl mb-8 text-slate-700">{t('crm.headers.invoices')}</h1>
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="font-medium text-gray-500 bg-gray-100 text-xs">
                <th></th>
                <th className="p-3 text-start">{t('invoices.fields.number')}</th>
                <th className="p-3 text-end">{t('payment.amount')}</th>
                <th className="p-3 text-end">{t('invoices.fields.toPay')}</th>
                <th className="p-3 text-end">{t('invoices.fields.issueDate')}</th>
                <th className="p-3 text-end">{t('invoices.fields.paymentTerm')}</th>
                <th className="p-3 text-end">{t('invoices.fields.paymentDate')}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {invoices?.filter(invoice => currentTab === 'unpaid' ? ((invoice?.valueRemain?.gross ?? 0) > 0) : ((invoice?.valueRemain?.gross ?? 0) <= 0)).map((invoice, index) => <InvoiceRow key={index} invoice={invoice} selected={selected.includes(invoice.id!)} onSelect={(v) => toggleSelect(invoice.id!, v)} onClickDownload={() => download(invoice)} onClickPayment={() => payment(invoice)} />)}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default InvoicesView;