import i18next from 'i18next';
import languageDetector from 'i18next-browser-languagedetector';
import XHR from "i18next-http-backend";
import { initReactI18next } from 'react-i18next';

import translationsEN from "./en/translation.json"
import translationsPL from "./pl/translation.json"
import configuration from 'src/config/config';

const resources = {
  en: { translation: translationsEN },
  pl: { translation: translationsPL },
};

const options = {
  debug: false,
  detection: {
    order: ['path', 'localStorage', 'navigator'],
  },
  resources,
  fallbackLng: configuration.defaultLocale,
  supportedLngs: configuration.locales,
  nonExplicitSupportedLngs: true,
  returnNull: false,
  react: {
    useSuspense: false,
    wait: configuration && configuration.locales
  }
};

if (!i18next.isInitialized) {
  i18next
    .use(initReactI18next)
    .use(XHR)
    .use(languageDetector)
    .init(options);
}

export default i18next;