import { useTranslation } from "react-i18next";

const CanceledInfo = () => {
  const { t } = useTranslation();
  return (
    <div className="p-5 flex-1">
      <h3 className="text-gray-500 mb-3">{t('licensing.paymentStatus')}</h3>
      <h2 className="text-gray-700 text-xl pt-1">
        <span className="text-rose-700">{t('licensing.canceled')}</span>
      </h2>
    </div>
  )
}

export default CanceledInfo;