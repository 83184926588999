import { LabelHTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";

export default ({
  className,
  ...otherProps
}: LabelHTMLAttributes<HTMLLabelElement>) => {
  if (!otherProps.children) return <></>;
  return (
    <label className={twMerge("text-red-700 text-xs mt-1", className)} {...otherProps} />
  )
}