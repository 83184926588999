import { ChangeEvent, ChangeEventHandler, createRef, InputHTMLAttributes, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import simulateChangeEvent from "./Form/simulateChangeEvent";
import { Label, SelectField, TextField } from "./Fields";
import CountryName from "./i18n/CountryName";
import { EnabledRegions } from "./i18n/clientRegion";
import { useRegion } from "src/hooks/useRegion";

const PhoneNumberCountryCodes = [
  { id: 'ad', regions: ['ad'], label: '+376', value: '376', length: [6, 6] },
  { id: 'ae', regions: ['ae'], label: '+971', value: '971', length: [9, 9] },
  { id: 'af', regions: ['af'], label: '+93', value: '93', length: [9, 9] },
  { id: 'ag', regions: ['ag'], label: '+1268', value: '1268', length: [10, 10] },
  { id: 'ai', regions: ['ai'], label: '+1264', value: '1264', length: [10, 10] },
  { id: 'al', regions: ['al'], label: '+355', value: '355', length: [9, 9] },
  { id: 'am', regions: ['am'], label: '+374', value: '374', length: [6, 6] },
  { id: 'ao', regions: ['ao'], label: '+244', value: '244', length: [9, 9] },
  { id: 'aq', regions: ['aq'], label: '+672', value: '672', length: [6, 6] },
  { id: 'ar', regions: ['ar'], label: '+54', value: '54', length: [6, 8] },
  { id: 'as', regions: ['as'], label: '+1684', value: '1684', length: [10, 10] },
  { id: 'at', regions: ['at'], label: '+43', value: '43', length: [10, 11] },
  { id: 'au', regions: ['au'], label: '+61', value: '61', length: [9, 9] },
  { id: 'aw', regions: ['aw'], label: '+297', value: '297', length: [7, 7] },
  { id: 'ax', regions: ['ax'], label: '+358', value: '358', length: [7, 10] },
  { id: 'az', regions: ['az'], label: '+994', value: '994', length: [9, 9] },
  { id: 'ba', regions: ['ba'], label: '+387', value: '387', length: [8, 8] },
  { id: 'bb', regions: ['bb'], label: '+1246', value: '1246', length: [10, 10] },
  { id: 'bd', regions: ['bd'], label: '+880', value: '880', length: [10, 10] },
  { id: 'be', regions: ['be'], label: '+32', value: '32', length: [9, 9] },
  { id: 'bf', regions: ['bf'], label: '+226', value: '226', length: [8, 8] },
  { id: 'bg', regions: ['bg'], label: '+359', value: '359', length: [9, 9] },
  { id: 'bh', regions: ['bh'], label: '+973', value: '973', length: [8, 8] },
  { id: 'bi', regions: ['bi'], label: '+257', value: '257', length: [8, 8] },
  { id: 'bj', regions: ['bj'], label: '+229', value: '229', length: [8, 8] },
  { id: 'bl', regions: ['bl'], label: '+590', value: '590', length: [9, 9] },
  { id: 'bm', regions: ['bm'], label: '+1441', value: '1441', length: [10, 10] },
  { id: 'bn', regions: ['bn'], label: '+673', value: '673', length: [7, 7] },
  { id: 'bo', regions: ['bo'], label: '+591', value: '591', length: [9, 9] },
  { id: 'br', regions: ['br'], label: '+55', value: '55', length: [11, 11] },
  { id: 'bs', regions: ['bs'], label: '+1242', value: '1242', length: [10, 10] },
  { id: 'bt', regions: ['bt'], label: '+975', value: '975', length: [7, 7] },
  { id: 'bv', regions: ['bv'], label: '+47', value: '47', length: [10, 10] },
  { id: 'bw', regions: ['bw'], label: '+267', value: '267', length: [7, 7] },
  { id: 'by', regions: ['by'], label: '+375', value: '375', length: [9, 9] },
  { id: 'bz', regions: ['bz'], label: '+501', value: '501', length: [7, 7] },
  { id: 'ca', regions: ['ca'], label: '+1', value: '1', length: [10, 10] },
  { id: 'cc', regions: ['cc'], label: '+61', value: '61', length: [10, 10] },
  { id: 'cd', regions: ['cd'], label: '+243', value: '243', length: [7, 7] },
  { id: 'cf', regions: ['cf'], label: '+236', value: '236', length: [8, 8] },
  { id: 'cg', regions: ['cg'], label: '+242', value: '242', length: [9, 9] },
  { id: 'ch', regions: ['ch'], label: '+41', value: '41', length: [9, 9] },
  { id: 'ci', regions: ['ci'], label: '+225', value: '225', length: [8, 8] },
  { id: 'ck', regions: ['ck'], label: '+682', value: '682', length: [5, 5] },
  { id: 'cl', regions: ['cl'], label: '+56', value: '56', length: [9, 9] },
  { id: 'cm', regions: ['cm'], label: '+237', value: '237', length: [9, 9] },
  { id: 'cn', regions: ['cn'], label: '+86', value: '86', length: [11, 11] },
  { id: 'co', regions: ['co'], label: '+57', value: '57', length: [10, 10] },
  { id: 'cr', regions: ['cr'], label: '+506', value: '506', length: [8, 8] },
  { id: 'cu', regions: ['cu'], label: '+53', value: '53', length: [8, 8] },
  { id: 'cv', regions: ['cv'], label: '+238', value: '238', length: [7, 7] },
  { id: 'cw', regions: ['cw'], label: '+599', value: '599', length: [7, 7] },
  { id: 'cx', regions: ['cx'], label: '+61', value: '61', length: [9, 9] },
  { id: 'cy', regions: ['cy'], label: '+357', value: '357', length: [8, 8] },
  { id: 'cz', regions: ['cz'], label: '+420', value: '420', length: [9, 9] },
  { id: 'de', regions: ['de'], label: '+49', value: '49', length: [10, 10] },
  { id: 'dj', regions: ['dj'], label: '+253', value: '253', length: [10, 10] },
  { id: 'dk', regions: ['dk'], label: '+45', value: '45', length: [8, 8] },
  { id: 'dm', regions: ['dm'], label: '+1767', value: '1767', length: [10, 10] },
  { id: 'do', regions: ['do'], label: '+1809', value: '1809', length: [10, 10] },
  { id: 'dz', regions: ['dz'], label: '+213', value: '213', length: [9, 9] },
  { id: 'ec', regions: ['ec'], label: '+593', value: '593', length: [9, 9] },
  { id: 'ee', regions: ['ee'], label: '+372', value: '372', length: [8, 8] },
  { id: 'eg', regions: ['eg'], label: '+20', value: '20', length: [10, 10] },
  { id: 'eh', regions: ['eh'], label: '+212', value: '212', length: [9, 9] },
  { id: 'er', regions: ['er'], label: '+291', value: '291', length: [7, 7] },
  { id: 'es', regions: ['es'], label: '+34', value: '34', length: [9, 9] },
  { id: 'et', regions: ['et'], label: '+251', value: '251', length: [9, 9] },
  { id: 'fi', regions: ['fi'], label: '+358', value: '358', length: [9, 11] },
  { id: 'fj', regions: ['fj'], label: '+679', value: '679', length: [7, 7] },
  { id: 'fk', regions: ['fk'], label: '+500', value: '500', length: [5, 5] },
  { id: 'fm', regions: ['fm'], label: '+691', value: '691', length: [7, 7] },
  { id: 'fo', regions: ['fo'], label: '+298', value: '298', length: [5, 5] },
  { id: 'fr', regions: ['fr'], label: '+33', value: '33', length: [9, 9] },
  { id: 'ga', regions: ['ga'], label: '+241', value: '241', length: [7, 7] },
  { id: 'gb', regions: ['gb'], label: '+44', value: '44', length: [10, 10] },
  { id: 'gd', regions: ['gd'], label: '+1473', value: '1473', length: [10, 10] },
  { id: 'ge', regions: ['ge'], label: '+995', value: '995', length: [9, 9] },
  { id: 'gf', regions: ['gf'], label: '+594', value: '594', length: [9, 9] },
  { id: 'gg', regions: ['gg'], label: '+44', value: '44', length: [10, 10] },
  { id: 'gh', regions: ['gh'], label: '+233', value: '233', length: [9, 9] },
  { id: 'gi', regions: ['gi'], label: '+350', value: '350', length: [8, 8] },
  { id: 'gl', regions: ['gl'], label: '+299', value: '299', length: [6, 6] },
  { id: 'gm', regions: ['gm'], label: '+220', value: '220', length: [7, 7] },
  { id: 'gn', regions: ['gn'], label: '+224', value: '224', length: [9, 9] },
  { id: 'gp', regions: ['gp'], label: '+590', value: '590', length: [9, 9] },
  { id: 'gq', regions: ['gq'], label: '+240', value: '240', length: [9, 9] },
  { id: 'gr', regions: ['gr'], label: '+30', value: '30', length: [10, 10] },
  { id: 'gs', regions: ['gs'], label: '+500', value: '500', length: [5, 5] },
  { id: 'gt', regions: ['gt'], label: '+502', value: '502', length: [8, 8] },
  { id: 'gu', regions: ['gu'], label: '+1671', value: '1671', length: [10, 10] },
  { id: 'gw', regions: ['gw'], label: '+245', value: '245', length: [9, 9] },
  { id: 'gy', regions: ['gy'], label: '+592', value: '592', length: [7, 7] },
  { id: 'hk', regions: ['hk'], label: '+852', value: '852', length: [8, 8] },
  { id: 'hm', regions: ['hm'], label: '+672', value: '672', length: [10, 10] },
  { id: 'hn', regions: ['hn'], label: '+504', value: '504', length: [8, 8] },
  { id: 'hr', regions: ['hr'], label: '+385', value: '385', length: [9, 9] },
  { id: 'ht', regions: ['ht'], label: '+509', value: '509', length: [8, 8] },
  { id: 'hu', regions: ['hu'], label: '+36', value: '36', length: [9, 9] },
  { id: 'id', regions: ['id'], label: '+62', value: '62', length: [11, 11] },
  { id: 'ie', regions: ['ie'], label: '+353', value: '353', length: [9, 9] },
  { id: 'il', regions: ['il'], label: '+972', value: '972', length: [9, 9] },
  { id: 'im', regions: ['im'], label: '+44', value: '44', length: [10, 10] },
  { id: 'in', regions: ['in'], label: '+91', value: '91', length: [10, 10] },
  { id: 'io', regions: ['io'], label: '+246', value: '246', length: [7, 7] },
  { id: 'iq', regions: ['iq'], label: '+964', value: '964', length: [10, 10] },
  { id: 'ir', regions: ['ir'], label: '+98', value: '98', length: [11, 11] },
  { id: 'is', regions: ['is'], label: '+354', value: '354', length: [7, 7] },
  { id: 'it', regions: ['it'], label: '+39', value: '39', length: [10, 10] },
  { id: 'je', regions: ['je'], label: '+44', value: '44', length: [10, 10] },
  { id: 'jm', regions: ['jm'], label: '+1876', value: '1876', length: [10, 10] },
  { id: 'jo', regions: ['jo'], label: '+962', value: '962', length: [8, 9] },
  { id: 'jp', regions: ['jp'], label: '+81', value: '81', length: [11, 11] },
  { id: 'ke', regions: ['ke'], label: '+254', value: '254', length: [10, 10] },
  { id: 'kg', regions: ['kg'], label: '+996', value: '996', length: [9, 9] },
  { id: 'kh', regions: ['kh'], label: '+855', value: '855', length: [9, 9] },
  { id: 'ki', regions: ['ki'], label: '+686', value: '686', length: [8, 8] },
  { id: 'km', regions: ['km'], label: '+269', value: '269', length: [7, 7] },
  { id: 'kn', regions: ['kn'], label: '+1869', value: '1869', length: [10, 10] },
  { id: 'kp', regions: ['kp'], label: '+850', value: '850', length: [4, 13] },
  { id: 'kr', regions: ['kr'], label: '+82', value: '82', length: [7, 8] },
  { id: 'kw', regions: ['kw'], label: '+965', value: '965', length: [8, 8] },
  { id: 'ky', regions: ['ky'], label: '+1345', value: '1345', length: [7, 7] },
  { id: 'kz', regions: ['kz'], label: '+7', value: '7', length: [10, 10] },
  { id: 'la', regions: ['la'], label: '+856', value: '856', length: [8, 9] },
  { id: 'lb', regions: ['lb'], label: '+961', value: '961', length: [7, 8] },
  { id: 'lc', regions: ['lc'], label: '+1758', value: '1758', length: [7, 7] },
  { id: 'li', regions: ['li'], label: '+423', value: '423', length: [7, 7] },
  { id: 'lk', regions: ['lk'], label: '+94', value: '94', length: [7, 7] },
  { id: 'lr', regions: ['lr'], label: '+231', value: '231', length: [8, 9] },
  { id: 'ls', regions: ['ls'], label: '+266', value: '266', length: [8, 8] },
  { id: 'lt', regions: ['lt'], label: '+370', value: '370', length: [8, 8] },
  { id: 'lu', regions: ['lu'], label: '+352', value: '352', length: [9, 9] },
  { id: 'lv', regions: ['lv'], label: '+371', value: '371', length: [8, 8] },
  { id: 'ly', regions: ['ly'], label: '+218', value: '218', length: [10, 10] },
  { id: 'ma', regions: ['ma'], label: '+212', value: '212', length: [9, 9] },
  { id: 'mc', regions: ['mc'], label: '+377', value: '377', length: [8, 8] },
  { id: 'md', regions: ['md'], label: '+373', value: '373', length: [8, 8] },
  { id: 'me', regions: ['me'], label: '+382', value: '382', length: [8, 8] },
  { id: 'mf', regions: ['mf'], label: '+590', value: '590', length: [6, 6] },
  { id: 'mg', regions: ['mg'], label: '+261', value: '261', length: [7, 7] },
  { id: 'mh', regions: ['mh'], label: '+692', value: '692', length: [7, 7] },
  { id: 'mk', regions: ['mk'], label: '+389', value: '389', length: [8, 8] },
  { id: 'ml', regions: ['ml'], label: '+223', value: '223', length: [8, 8] },
  { id: 'mm', regions: ['mm'], label: '+95', value: '95', length: [7, 10] },
  { id: 'mn', regions: ['mn'], label: '+976', value: '976', length: [8, 8] },
  { id: 'mo', regions: ['mo'], label: '+853', value: '853', length: [8, 8] },
  { id: 'mp', regions: ['mp'], label: '+1670', value: '1670', length: [7, 7] },
  { id: 'mq', regions: ['mq'], label: '+596', value: '596', length: [9, 9] },
  { id: 'mr', regions: ['mr'], label: '+222', value: '222', length: [8, 8] },
  { id: 'ms', regions: ['ms'], label: '+1664', value: '1664', length: [10, 10] },
  { id: 'mt', regions: ['mt'], label: '+356', value: '356', length: [8, 8] },
  { id: 'mu', regions: ['mu'], label: '+230', value: '230', length: [8, 8] },
  { id: 'mv', regions: ['mv'], label: '+960', value: '960', length: [7, 7] },
  { id: 'mw', regions: ['mw'], label: '+265', value: '265', length: [7, 9] },
  { id: 'mx', regions: ['mx'], label: '+52', value: '52', length: [10, 10] },
  { id: 'my', regions: ['my'], label: '+60', value: '60', length: [7, 7] },
  { id: 'mz', regions: ['mz'], label: '+258', value: '258', length: [12, 12] },
  { id: 'na', regions: ['na'], label: '+264', value: '264', length: [7, 7] },
  { id: 'nc', regions: ['nc'], label: '+687', value: '687', length: [6, 6] },
  { id: 'ne', regions: ['ne'], label: '+227', value: '227', length: [8, 8] },
  { id: 'nf', regions: ['nf'], label: '+672', value: '672', length: [6, 6] },
  { id: 'ng', regions: ['ng'], label: '+234', value: '234', length: [8, 8] },
  { id: 'ni', regions: ['ni'], label: '+505', value: '505', length: [8, 8] },
  { id: 'nl', regions: ['nl'], label: '+31', value: '31', length: [9, 9] },
  { id: 'no', regions: ['no'], label: '+47', value: '47', length: [8, 8] },
  { id: 'np', regions: ['np'], label: '+977', value: '977', length: [10, 10] },
  { id: 'nr', regions: ['nr'], label: '+674', value: '674', length: [7, 7] },
  { id: 'nu', regions: ['nu'], label: '+683', value: '683', length: [4, 4] },
  { id: 'nz', regions: ['nz'], label: '+64', value: '64', length: [8, 9] },
  { id: 'om', regions: ['om'], label: '+968', value: '968', length: [8, 8] },
  { id: 'pa', regions: ['pa'], label: '+507', value: '507', length: [8, 8] },
  { id: 'pe', regions: ['pe'], label: '+51', value: '51', length: [9, 9] },
  { id: 'pf', regions: ['pf'], label: '+689', value: '689', length: [8, 8] },
  { id: 'pg', regions: ['pg'], label: '+675', value: '675', length: [8, 8] },
  { id: 'ph', regions: ['ph'], label: '+63', value: '63', length: [10, 10] },
  { id: 'pk', regions: ['pk'], label: '+92', value: '92', length: [10, 10] },
  { id: 'pl', regions: ['pl'], label: '+48', value: '48', length: [9, 9] },
  { id: 'pm', regions: ['pm'], label: '+508', value: '508', length: [6, 6] },
  { id: 'pn', regions: ['pn'], label: '+870', value: '870', length: [9, 9] },
  { id: 'pr', regions: ['pr'], label: '+1', value: '1', length: [10, 10] },
  { id: 'ps', regions: ['ps'], label: '+970', value: '970', length: [9, 9] },
  { id: 'pt', regions: ['pt'], label: '+351', value: '351', length: [9, 9] },
  { id: 'pw', regions: ['pw'], label: '+680', value: '680', length: [7, 7] },
  { id: 'py', regions: ['py'], label: '+595', value: '595', length: [9, 9] },
  { id: 'qa', regions: ['qa'], label: '+974', value: '974', length: [8, 8] },
  { id: 're', regions: ['re'], label: '+262', value: '262', length: [10, 10] },
  { id: 'ro', regions: ['ro'], label: '+40', value: '40', length: [10, 10] },
  { id: 'rs', regions: ['rs'], label: '+381', value: '381', length: [9, 9] },
  { id: 'ru', regions: ['ru'], label: '+7', value: '7', length: [10, 10] },
  { id: 'rw', regions: ['rw'], label: '+250', value: '250', length: [9, 9] },
  { id: 'sa', regions: ['sa'], label: '+966', value: '966', length: [9, 9] },
  { id: 'sb', regions: ['sb'], label: '+677', value: '677', length: [7, 7] },
  { id: 'sc', regions: ['sc'], label: '+248', value: '248', length: [7, 7] },
  { id: 'sd', regions: ['sd'], label: '+249', value: '249', length: [7, 7] },
  { id: 'se', regions: ['se'], label: '+46', value: '46', length: [7, 7] },
  { id: 'sg', regions: ['sg'], label: '+65', value: '65', length: [8, 8] },
  { id: 'sh', regions: ['sh'], label: '+290', value: '290', length: [4, 4] },
  { id: 'si', regions: ['si'], label: '+386', value: '386', length: [9, 9] },
  { id: 'sj', regions: ['sj'], label: '+47', value: '47', length: [8, 8] },
  { id: 'sk', regions: ['sk'], label: '+421', value: '421', length: [9, 9] },
  { id: 'sl', regions: ['sl'], label: '+232', value: '232', length: [8, 8] },
  { id: 'sm', regions: ['sm'], label: '+378', value: '378', length: [10, 10] },
  { id: 'sn', regions: ['sn'], label: '+221', value: '221', length: [9, 9] },
  { id: 'so', regions: ['so'], label: '+252', value: '252', length: [8, 9] },
  { id: 'sr', regions: ['sr'], label: '+597', value: '597', length: [6, 7] },
  { id: 'ss', regions: ['ss'], label: '+211', value: '211', length: [7, 7] },
  { id: 'st', regions: ['st'], label: '+239', value: '239', length: [7, 7] },
  { id: 'sv', regions: ['sv'], label: '+503', value: '503', length: [8, 8] },
  { id: 'sx', regions: ['sx'], label: '+1721', value: '1721', length: [10, 10] },
  { id: 'sy', regions: ['sy'], label: '+963', value: '963', length: [7, 7] },
  { id: 'sz', regions: ['sz'], label: '+268', value: '268', length: [8, 8] },
  { id: 'tc', regions: ['tc'], label: '+1649', value: '1649', length: [10, 10] },
  { id: 'td', regions: ['td'], label: '+235', value: '235', length: [6, 6] },
  { id: 'tf', regions: ['tf'], label: '+262', value: '262', length: [10, 10] },
  { id: 'tg', regions: ['tg'], label: '+228', value: '228', length: [8, 8] },
  { id: 'th', regions: ['th'], label: '+66', value: '66', length: [9, 9] },
  { id: 'tj', regions: ['tj'], label: '+992', value: '992', length: [9, 9] },
  { id: 'tk', regions: ['tk'], label: '+690', value: '690', length: [5, 5] },
  { id: 'tl', regions: ['tl'], label: '+670', value: '670', length: [7, 7] },
  { id: 'tm', regions: ['tm'], label: '+993', value: '993', length: [8, 8] },
  { id: 'tn', regions: ['tn'], label: '+216', value: '216', length: [8, 8] },
  { id: 'to', regions: ['to'], label: '+676', value: '676', length: [5, 5] },
  { id: 'tr', regions: ['tr'], label: '+90', value: '90', length: [11, 11] },
  { id: 'tt', regions: ['tt'], label: '+1868', value: '1868', length: [7, 7] },
  { id: 'tv', regions: ['tv'], label: '+688', value: '688', length: [5, 5] },
  { id: 'tw', regions: ['tw'], label: '+886', value: '886', length: [9, 9] },
  { id: 'tz', regions: ['tz'], label: '+255', value: '255', length: [7, 7] },
  { id: 'ua', regions: ['ua'], label: '+380', value: '380', length: [9, 9] },
  { id: 'ug', regions: ['ug'], label: '+256', value: '256', length: [7, 7] },
  { id: 'us', regions: ['us'], label: '+1', value: '1', length: [10, 10] },
  { id: 'uy', regions: ['uy'], label: '+598', value: '598', length: [8, 8] },
  { id: 'uz', regions: ['uz'], label: '+998', value: '998', length: [9, 9] },
  { id: 'va', regions: ['va'], label: '+379', value: '379', length: [10, 10] },
  { id: 'vc', regions: ['vc'], label: '+1784', value: '1784', length: [7, 7] },
  { id: 've', regions: ['ve'], label: '+58', value: '58', length: [7, 7] },
  { id: 'vg', regions: ['vg'], label: '+1284', value: '1284', length: [7, 7] },
  { id: 'vi', regions: ['vi'], label: '+1340', value: '1340', length: [10, 10] },
  { id: 'vn', regions: ['vn'], label: '+84', value: '84', length: [9, 9] },
  { id: 'vu', regions: ['vu'], label: '+678', value: '678', length: [5, 5] },
  { id: 'wf', regions: ['wf'], label: '+681', value: '681', length: [6, 6] },
  { id: 'ws', regions: ['ws'], label: '+685', value: '685', length: [5, 7] },
  { id: 'xk', regions: ['xk'], label: '+383', value: '383', length: [8, 8] },
  { id: 'ye', regions: ['ye'], label: '+967', value: '967', length: [9, 9] },
  { id: 'yt', regions: ['yt'], label: '+262', value: '262', length: [9, 9] },
  { id: 'za', regions: ['za'], label: '+27', value: '27', length: [9, 9] },
  { id: 'zm', regions: ['zm'], label: '+260', value: '260', length: [9, 9] },
  { id: 'zw', regions: ['zw'], label: '+263', value: '263', length: [9, 9] },
]

interface PhoneNumberFieldComponents extends InputHTMLAttributes<HTMLInputElement> {
  containerClassName?: string;
  selectClassName?: string;
  inputClassName?: string;
  label?: string;
}

interface PhoneNumber {
  countryCode?: string;
  number?: string;
}

const PhoneNumberField = (props: PhoneNumberFieldComponents) => {
  const {
    name, value, label, disabled, readOnly, required, onChange,
    containerClassName, selectClassName, inputClassName,
    ...otherProps
  } = props;
  const { region } = useRegion();
  const { t } = useTranslation();
  const [countryCode, setCountryCode] = useState<string>();
  const [number, setNumber] = useState<string>();
  const inputRef = createRef<HTMLInputElement>();

  const onChangeCountryCode: ChangeEventHandler<HTMLSelectElement> = (e) => {
    setCountryCode(e.target.value);
    onChangeMiddleware({ countryCode: e.target.value, number });
  }

  const onChangeNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
    setNumber(e.target.value);
    onChangeMiddleware({ countryCode, number: e.target.value });
  }

  const getFormattedValue = (value: PhoneNumber) => [value.countryCode, value.number].join('');

  const decomposeValue = (composedNumber?: string) => {
    if (!composedNumber) return ['', ''];
    let newNumber = '';
    let newCountryCode = '';
    const tmp = composedNumber
      .trim()
      .replace('+', '')
      .replace(' ', '');
    PhoneNumberCountryCodes.map(cc => {
      if (!tmp.startsWith(cc.value)) return false;
      const t = tmp.substring(cc.value.length);
      if (cc.length.length == 1 && t.length != cc.length[0]) return false;
      if (cc.length.length == 2 && (t.length < cc.length[0] || t.length > cc.length[1])) return false;
      newCountryCode = cc.value;
      newNumber = t;
      return true;
    });
    return [newCountryCode || '', newNumber || ''];
  }

  const onChangeMiddleware = (value: PhoneNumber) => {
    if (onChange) {
      const formattedValue = getFormattedValue(value);
      const event: ChangeEvent<HTMLInputElement> = {
        bubbles: true,
        cancelable: true,
        currentTarget: inputRef.current!,
        nativeEvent: new Event('change'),
        defaultPrevented: false,
        eventPhase: 0,
        isTrusted: true,
        preventDefault: () => { return; },
        stopPropagation: () => { return; },
        isDefaultPrevented: () => false,
        isPropagationStopped: () => false,
        persist: () => { return; },
        timeStamp: 0,
        type: 'change',
        target: {
          value: formattedValue,
          name,
        } as never
      };
      onChange(event);
    }
  }

  useEffect(() => {
    if (!countryCode && region) {
      const cultureCountryCode = PhoneNumberCountryCodes.find(cc => cc.regions.includes(region));
      if (cultureCountryCode) {
        setCountryCode(cultureCountryCode.value);
        return;
      }
      setCountryCode(PhoneNumberCountryCodes.find(_cc => true)?.value);
    }
  }, [region, countryCode]);

  useEffect(() => {
    const [newCountryCode, newNumber] = decomposeValue(String(value));
    if (newCountryCode && newNumber) {
      setCountryCode(newCountryCode);
      setNumber(newNumber);
      simulateChangeEvent(inputRef.current, `${newCountryCode}${newNumber}`);
    }
  }, [value]);

  const availablePhoneCountryCodes = useMemo(
    () => {
      const regions = EnabledRegions.map(r => r.countryCode);
      return PhoneNumberCountryCodes.filter(cc => regions.includes(cc.id));
    },
    [EnabledRegions]
  );

  const currentCountryCode = useMemo(() => PhoneNumberCountryCodes.find(cc => cc.value == countryCode), [countryCode]);

  const pattern = useMemo(() => {
    if (!currentCountryCode) return;
    if (currentCountryCode.length.length == 1) return `\\d{${currentCountryCode.length[0]}}`;
    return `\\d{${currentCountryCode.length[0]},${currentCountryCode.length[1]}}`;
  }, [currentCountryCode])

  return (
    <>
      <div className={containerClassName}>
        <label htmlFor="number" className="block text-xs font-medium text-gray-900">
          {label && <Label id={otherProps.id ?? ""}>{label} {props.required && <span className="text-red-700">*</span>}</Label>}
        </label>
        <div className="flex" >
          <div className="flex-initial w-24" >
            <SelectField
              name="countryCode"
              value={countryCode}
              onChange={onChangeCountryCode}
              className={selectClassName}
              disabled={disabled}
            >
              {availablePhoneCountryCodes.map(cc => (
                <option key={cc.id} value={cc.value}>{cc.label} (<CountryName countryCode={cc.id} />)</option>
              ))}
            </SelectField>
          </div>
          <div className="flex-grow" >
            <TextField
              name="number"
              type="text"
              min={0}
              step={1}
              value={number}
              onChange={onChangeNumber}
              className={inputClassName}
              placeholder={t('auth.fields.phoneNumber')}
              minLength={currentCountryCode?.length ? currentCountryCode.length[0] : undefined}
              maxLength={currentCountryCode?.length ? (currentCountryCode.length.length == 2 ? currentCountryCode.length[1] : currentCountryCode.length[0]) : undefined}
              disabled={disabled}
              required={required}
              readOnly={readOnly}
              pattern={pattern}
            />
          </div>
          <input ref={inputRef} type="hidden" name={name} onChange={onChange} />
        </div>
      </div >
    </>
  )
}

export default PhoneNumberField;