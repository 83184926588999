import useForm from "src/hooks/useForm";
import { Button } from "./Button";
import PageLink from "./Cms/PageLink";
import { NewsletterClient, NewsletterRevoke, NewsletterSignIn, NewsletterVerify } from "src/api/notifications/Notifications";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FormAlert from "./Feedback/FormAlert";
import Spinner from "./Feedback/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-light-svg-icons";
import Alert from "./Feedback/Alert";
import { useParams } from "react-router";
import Feedback from "./Feedback";

export enum NewsletterAction {
  SignIn,
  Verify,
  Revoke,
  Verified,
  Revoked
}

interface NewsletterFormProps {
  setAction: (action: NewsletterAction) => void;
  email?: string;
  setEmail?: (email: string) => void;
  code?: string;
  setCode?: (code: string) => void;
  codeSent?: boolean;
  setCodeSent?: (codeSent: boolean) => void;
}

export function NewsletterSignInForm({ setAction, email, setEmail, setCodeSent }: NewsletterFormProps) {
  const { i18n, t } = useTranslation();
  const form = useForm({ email } as NewsletterSignIn);
  const apiConfiguration = useApiConfiguration();
  const apiClient = new NewsletterClient(apiConfiguration);
  useEffect(() => { if (!form.data.email || !setEmail) return; setEmail(form.data.email); }, [form.data.email]);
  const onSubmit = () => {
    if (form.pending) return;
    const data = { ...form.data, culture: i18n.resolvedLanguage };
    apiClient
      .signIn(data as NewsletterSignIn)
      .then(() => {
        if (setCodeSent) setCodeSent(true);
        setAction(NewsletterAction.Verify)
      })
      .catch(ex => form.catchAnyException(ex))
      .finally(() => form.setPending(false));
  };

  return (
    <div>
      <form className="w-full max-w-md flex flex-col gap-y-5" onSubmit={e => form.onSubmit(e, onSubmit)}>
        <FormAlert
          code={form.error}
          errorMessages={{ ...t('common.errors', { returnObjects: true }), ...t('newsletter.errors', { returnObjects: true }) }} />
        <div className="flex flex-col">
          <label htmlFor="email-address" className="sr-only">
            {t('auth.fields.email')}
          </label>
          <input
            className="bg-white min-w-0 flex-auto rounded-md px-3.5 py-2 text-base text-gray-800 outline outline-1 -outline-offset-1 outline-white/10 placeholder:text-gray-500 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-white sm:text-sm/6"
            {...form.input('email', 'email', { placeholder: t('auth.fields.email'), autoComplete: 'email', required: true })}
          />
          <Feedback {...form.validation('email')} />
        </div>
        <div className="flex flex-col">
          <label htmlFor="email-address" className="sr-only">
            {t('auth.fields.givenName')}
          </label>
          <input
            className="bg-white min-w-0 flex-auto rounded-md px-3.5 py-2 text-base text-gray-800 outline outline-1 -outline-offset-1 outline-white/10 placeholder:text-gray-500 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-white sm:text-sm/6"
            {...form.input('givenName', 'text', { placeholder: t('auth.fields.givenName'), autoComplete: 'name', minLength: 2 })}
          />
          <Feedback {...form.validation('givenName')} />
        </div>
        <div className="flex flex-row items-center gap-x-5">
          {form.pending && <Spinner />}
          <Button color="secondary" className="flex-grow">
            {t('newsletter.signIn')}
          </Button>
        </div>
        <div className="mt-4 text-sm/6 text-gray-800 text-left">
          {t('newsletter.ourPrivacyPolicy')} <span className="font-medium transition-all ease-in-out duration-300 hover:scale-105 active:scale-95"><PageLink id="b7cdaccb-08cc-49f8-82ad-739e1f738378" /></span>.
        </div>
      </form>
      <p className="text-sm/6 text-gray-800 text-left cursor-pointer transition-all duration-300 hover:scale-105 active:scale-95" onClick={() => setAction(NewsletterAction.Verify)}>{t('newsletter.wantToVerify')}</p>
      <p className="text-sm/6 text-gray-800 text-left cursor-pointer transition-all duration-300 hover:scale-105 active:scale-95" onClick={() => setAction(NewsletterAction.Revoke)}>{t('newsletter.wantToCancel')}</p>
    </div>
  );
}

export function NewsletterVerifyForm({ setAction, email, setEmail, code, codeSent, setCodeSent }: NewsletterFormProps) {
  const { t } = useTranslation();
  const [sent, setSentCode] = useState(false);
  const form = useForm({ email, code } as NewsletterVerify);
  const apiConfiguration = useApiConfiguration();
  const apiClient = new NewsletterClient(apiConfiguration);
  useEffect(() => form.setData({ ...form.data, code: form.data.code?.replace(' ', '') } as NewsletterVerify), [form.data.code]);
  useEffect(() => { if (!form.data.email || !setEmail) return; setEmail(form.data.email); }, [form.data.email]);

  useEffect(() => {
    if (!codeSent || !setCodeSent) return;
    setSentCode(true);
    setCodeSent(false);
  }, [codeSent]);

  const onSubmit = () => {
    if (form.pending) return;
    const data = { ...form.data };
    apiClient
      .verify(data as NewsletterVerify)
      .then(() => setAction(NewsletterAction.Verified))
      .catch(ex => form.catchAnyException(ex))
      .finally(() => form.setPending(false));
  };

  const sendVerifyCode = () => {
    if (form.pending) return;
    form.setPending(true);
    const data = { email: form.data.email } as NewsletterRevoke;
    apiClient
      .sendCode(data)
      .then(() => {
        form.setPending(false);
        setSentCode(true);
      })
      .catch(ex => form.catchAnyException(ex))
      .finally(() => form.setPending(false));
  }

  return (
    <div>
      <form className="w-full max-w-md flex flex-col gap-y-5" onSubmit={e => form.onSubmit(e, onSubmit)}>
        {sent && <Alert.Information title={t('newsletter.codeSent')} />}
        <FormAlert
          code={form.error}
          errorMessages={{ ...t('common.errors', { returnObjects: true }), ...t('newsletter.verifyErrors', { returnObjects: true }) }} />
        <div className="flex flex-col">
          <label htmlFor="email-address" className="sr-only">
            {t('auth.fields.email')}
          </label>
          <input
            className="bg-white min-w-0 flex-auto rounded-md px-3.5 py-2 text-base text-gray-800 outline outline-1 -outline-offset-1 outline-white/10 placeholder:text-gray-500 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-white sm:text-sm/6"
            {...form.input('email', 'email', { placeholder: t('auth.fields.email'), autoComplete: 'email', required: true })}
          />
          <Feedback {...form.validation('email')} />
        </div>
        <div className="flex flex-col">
          <label htmlFor="email-address" className="sr-only">
            {t('auth.fields.verificationCode')}
          </label>
          <input
            className="bg-white min-w-0 flex-auto rounded-md px-3.5 py-2 text-base text-gray-800 outline outline-1 -outline-offset-1 outline-white/10 placeholder:text-gray-500 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-white sm:text-sm/6"
            {...form.input('code', 'text', { placeholder: t('auth.fields.verificationCode'), required: true, minLength: 6 })}
          />
          <Feedback {...form.validation('code')} />
        </div>
        <div className="flex flex-row items-center gap-x-5">
          <Button type="button" className="py-3" color="secondary" onClick={() => setAction(NewsletterAction.SignIn)}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </Button>
          {form.pending && <Spinner />}
          <Button color="secondary" className="flex-grow">
            {t('newsletter.verify')}
          </Button>
        </div>
        <p className="mt-4 text-sm/6 text-gray-800">
          {t('newsletter.ourPrivacyPolicy')} <span className="font-medium transition-all ease-in-out duration-300 hover:scale-105 active:scale-95"><PageLink id="b7cdaccb-08cc-49f8-82ad-739e1f738378" /></span>.
        </p>
      </form>
      {!sent && <p className="text-sm/6 text-gray-800 text-left cursor-pointer transition-all duration-300 hover:scale-105 active:scale-95" onClick={() => sendVerifyCode()}>{t('newsletter.sendCode')}</p>}
    </div>
  );
}

export function NewsletterRevokeForm({ setAction, email, setEmail }: NewsletterFormProps) {
  const { t } = useTranslation();
  const form = useForm({ email } as NewsletterRevoke);
  const apiConfiguration = useApiConfiguration();
  const apiClient = new NewsletterClient(apiConfiguration);
  useEffect(() => { if (!form.data.email || !setEmail) return; setEmail(form.data.email); }, [form.data.email]);
  const onSubmit = () => {
    if (form.pending) return;
    const data = { ...form.data };
    apiClient
      .revoke(data as NewsletterRevoke)
      .then(() => setAction(NewsletterAction.Revoked))
      .catch(ex => form.catchAnyException(ex))
      .finally(() => form.setPending(false));
  };

  return (
    <form className="w-full max-w-md flex flex-col gap-y-5" onSubmit={e => form.onSubmit(e, onSubmit)}>
      <div className="flex flex-col">
        <label htmlFor="email-address" className="sr-only">
          {t('auth.fields.email')}
        </label>
        <input
          className="bg-white min-w-0 flex-auto rounded-md px-3.5 py-2 text-base text-gray-800 outline outline-1 -outline-offset-1 outline-white/10 placeholder:text-gray-500 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-white sm:text-sm/6"
          {...form.input('email', 'email', { placeholder: t('auth.fields.email'), autoComplete: 'email', required: true })}
        />
        <Feedback {...form.validation('email')} />
      </div>
      <div className="flex flex-row items-center gap-x-5">
        <Button type="button" className="py-3" color="secondary" onClick={() => setAction(NewsletterAction.SignIn)}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </Button>
        {form.pending && <Spinner />}
        <Button color="secondary" className="flex-grow">
          {t('newsletter.signOff')}
        </Button>
      </div>
      <p className="mt-4 text-sm/6 text-gray-700">
        {t('newsletter.ourPrivacyPolicy')} <span className="font-medium transition-all ease-in-out duration-300 hover:scale-105 active:scale-95"><PageLink id="b7cdaccb-08cc-49f8-82ad-739e1f738378" /></span>.
      </p>
    </form >
  );
}

export function NewsletterVerified({ setAction }: NewsletterFormProps) {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex flex-col gap-y-3">
        <Alert.Information title={t('newsletter.verified')} noClose />
        <Button type="button" className="py-3" color="secondary" onClick={() => setAction(NewsletterAction.SignIn)}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </Button>
      </div>
    </>
  );
}
export function NewsletterRevoked({ setAction }: NewsletterFormProps) {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex flex-col gap-y-3">
        <Alert.Information title={t('newsletter.revoked')} noClose />
        <Button type="button" className="py-3" color="secondary" onClick={() => setAction(NewsletterAction.SignIn)}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </Button>
      </div>
    </>
  );
}

export default function NewsletterBanner({ defaultAction }: { defaultAction?: NewsletterAction}) {
  const params = useParams();
  const [action, setAction] = useState(defaultAction ?? NewsletterAction.SignIn);
  const [email, setEmail] = useState(params.email ?? '');
  const [codeSent, setCodeSent] = useState(false);

  return (
    <>
      {action === NewsletterAction.SignIn && <NewsletterSignInForm setAction={setAction} email={email} setEmail={setEmail} setCodeSent={setCodeSent} />}
      {action === NewsletterAction.Verify && <NewsletterVerifyForm setAction={setAction} email={email} setEmail={setEmail} code={params.code} codeSent={codeSent} setCodeSent={setCodeSent} />}
      {action === NewsletterAction.Revoke && <NewsletterRevokeForm setAction={setAction} email={email} setEmail={setEmail} />}
      {action === NewsletterAction.Verified && <NewsletterVerified setAction={setAction} />}
      {action === NewsletterAction.Revoked && <NewsletterRevoked setAction={setAction} />}
    </>
  )
}
