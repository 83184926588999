'use client'

import { useTranslation } from "react-i18next"
import LocalizedLink from "../Router/LocalizedLink";
import ReactMarkdown from "react-markdown";
import { PhoneFrame } from "./Features";

function Motto() {
  const { i18n } = useTranslation();
  switch(i18n.resolvedLanguage) {
    case 'pl':
      return (
        <h1 className="text-pretty text-4xl font-branding tracking-tight text-gray-900 sm:text-6xl">
          Puść <span className="text-secondary-500">wodze</span> fantazji<br />
          i zaprojektuj<br />
          wymarzoną stronę<br />
          dla <span className="text-primary-700">Twojej stajni</span>
        </h1>
      );
    default:
      return (
        <h1 className="text-pretty text-4xl font-branding tracking-tight text-gray-900 sm:text-6xl">
          <span className="text-secondary-500">Rein in</span><br />
          your creativity and<br />
          run a dream website<br />
          for <span className="text-primary-700">Your stable</span>
        </h1>
      )
  }
}

export default function Hero() {
  const { t, i18n } = useTranslation();
  return (
    <div className="bg-white" id="#hero">
      <div className="relative overflow-hidden">
        <div className="mx-auto max-w-7xl h-full">
          <div className="relative z-10 pt-14 lg:w-full lg:max-w-2xl lg:min-h-svh">
            <svg
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
              className="absolute inset-y-0 right-8 hidden w-90 min-h-svh translate-x-1/2 transform fill-white lg:block"
            >
              <polygon points="0,0 90,0 50,100 0,100" />
            </svg>
            <div className="relative px-6 py-16 sm:py-24 lg:px-8 lg:py-32 lg:pr-0">
              <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-2xl">
                <Motto />
                <ReactMarkdown children={t('landing.hero.slogan')} className="mt-8 text-pretty text-lg font-medium text-primary-900 sm:text-2xl" />
                <div className="mt-10 flex flex-col sm:flex-row items-center gap-6">
                  <LocalizedLink
                    to="/apply"
                    className="block text-center rounded-md bg-secondary-600 px-5 py-3 text font-medium text-white shadow-sm hover:bg-secondary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-600 transition-all hover:scale-105 active:scale-95"
                  >
                    {t('landing.hero.tryFreeMonth')}
                  </LocalizedLink>
                  <a href={`https://wizualizacja.redini.app/${i18n.resolvedLanguage}/`} target="_blank" rel="noopener" className="block text-center font-semibold text-gray-900 transition-all hover:scale-105 active:scale-95">
                    {t('landing.hero.demo')} <span aria-hidden="true">→</span>
                  </a>
                </div>
                <div className="hidden lg:block mt-8 lg:mt-32">
                  <div className="flex gap-5 my-5 justify-center lg:justify-normal">
                    <div>
                      <a href="https://apps.apple.com/pl/app/redini-aplikacja-je%C5%BAdziecka/id6737122460" target="_blank" rel="noreferrer">
                        <img className="h-12 w-auto transition-all hover:scale-105 active:scale-95" style={{ margin: '1px' }} src={`/images/mobile/${i18n?.resolvedLanguage}/app-store.webp`} alt={t('ui.getFromAppStore')} title={t('ui.getFromAppStore')} />
                      </a>
                    </div>
                    <div>
                      <a href="https://play.google.com/store/apps/details?id=com.h0pe.rediniapp" target="_blank" rel="noreferrer">
                        <img className="h-12 w-auto transition-all hover:scale-105 active:scale-95" src={`/images/mobile/${i18n?.resolvedLanguage}/google-play.webp`} alt={t('ui.getFromGooglePlay')} title={t('ui.getFromGooglePlay')} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:block bg-gray-50 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 overflow-hidden">
          <img
            alt="Hero background"
            src="/images/photos/hero.webp"
            className="aspect-[3/2] object-cover lg:aspect-auto opacity-25 object-right-bottom h-[125%] grayscale"
          />
        </div>
        <div className="mx-8 lg:mx-0 lg:absolute lg:inset-y-0 lg:-top-24 lg:right-16 lg:transform lg:translate-y-1/4 lg:translate-x-1/4 lg:w-1/2 z-20" >
            <img src={`/images/screenshots/${i18n?.resolvedLanguage}/desktop/hero.webp`} alt="screenshot" className="shadow-lg shadow-gray-500 rounded-lg" />
          </div>
          <PhoneFrame className="mx-auto lg:mx-0 w-1/3 -mt-56 md:-mt-96 lg:-mt-0 lg:absolute lg:-top-0 lg:right-16 xl:right-32 2xl:right-48 lg:transform lg:translate-y-1/4 lg:w-1/4 lg:max-w-64 xl:max-w-72 z-20" >
            <img src={`/images/screenshots/${i18n?.resolvedLanguage}/mobile/hero.webp`} alt="screenshot" className="size-full" />
          </PhoneFrame>
      </div>
      <div className="block lg:hidden mt-8 lg:mt-32">
        <div className="flex gap-5 my-5 justify-center lg:justify-normal">
          <div>
            <a href="https://apps.apple.com/pl/app/redini-aplikacja-je%C5%BAdziecka/id6737122460" target="_blank" rel="noreferrer">
              <img className="h-12 w-auto transition-all hover:scale-105 active:scale-95" style={{ margin: '1px' }} src={`/images/mobile/${i18n?.resolvedLanguage}/app-store.webp`} alt={t('ui.getFromAppStore')} title={t('ui.getFromAppStore')} />
            </a>
          </div>
          <div>
            <a href="https://play.google.com/store/apps/details?id=com.h0pe.rediniapp" target="_blank" rel="noreferrer">
              <img className="h-12 w-auto transition-all hover:scale-105 active:scale-95" src={`/images/mobile/${i18n?.resolvedLanguage}/google-play.webp`} alt={t('ui.getFromGooglePlay')} title={t('ui.getFromGooglePlay')} />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
