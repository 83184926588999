import { faChartLineUp, faCreditCard, faEnvelope, faRepeat, faUsers, IconDefinition } from "@fortawesome/duotone-thin-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import Data from "../../data/features/Features.json";
import useEntityTranslation from "src/hooks/useEntityTranslation";
import { faCheckCircle } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";

export interface FeatureTranslation {
  culture: string;
  title: string | React.ReactNode;
  slogan: string | React.ReactNode;
  description: string | React.ReactNode;
  bulletpoints?: string[];
  image: string;
}

export interface Feature {
  id: string;
  translations: FeatureTranslation[];
}

const features = Data as Feature[];

export function useScrollPercentage(ref: React.RefObject<HTMLElement>) {
  const [scrollPercentage, setScrollPercentage] = useState(0);

  useEffect(() => {
    const element = ref.current;
    if (!element) return;
    setScrollPercentage((element.scrollTop / (element.scrollHeight - element.clientHeight)) * 100);
  }, [window.scrollY, ref.current?.scrollTop]);

  return scrollPercentage;
}

function FeatureDisplay({ id, title, slogan, description, icon, bulletpoints, onVisible }: {
  id: string;
  title: string | React.ReactNode,
  slogan: string | React.ReactNode,
  description: string | React.ReactNode,
  bulletpoints?: string[],
  icon?: IconDefinition,
  onVisible?: () => void;
}) {
  const [opacity, setOpacity] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    const activeObserver = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          onVisible?.();
        }
      },
      {
        rootMargin: "-30% 0px -50% 0px",
        threshold: 0.2,
      }
    );
    const observer = new IntersectionObserver(
      ([entry]) => {
        const ratio = entry.intersectionRatio;
        setOpacity(ratio);
      },
      {
        threshold: Array.from({ length: 101 }, (_, i) => i / 100),
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
      activeObserver.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
        activeObserver.unobserve(ref.current);
      }
    };
  }, [ref.current]);

  return (
    <div id={id} className="my-16 lg:my-0 h-full lg:h-dvh flex flex-col justify-center" style={{ opacity: opacity }}>
      {icon && (<div className="absolute w-full text-center">
        <FontAwesomeIcon icon={icon} className="text-[15rem] lg:text-[35rem] mx-auto mt-16 opacity-5 text-secondary-500" />
      </div>)}
      <div ref={ref}>
        <h2 className="text-secondary-500">{slogan}</h2>
        <p className="mt-2 text-4xl font-medium tracking-tight font-branding text-primary-700 lg:text-5xl">{title}</p>
        <div className="mt-6 text-lg text-gray-600">
          {description}
        </div>
        {bulletpoints && (
          <ul>
            {bulletpoints?.map((point, i) => (
              <li key={i} className="mt-2 text-lg text-gray-600">
                <FontAwesomeIcon icon={faCheckCircle} className="text-secondary-500 mr-1" /> {point}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}

export default function Features() {
  const ref = useRef(null);
  const { t } = useTranslation();
  const [opacity, setOpacity] = useState(0);
  const [activeFeatureIndex, setActiveFeatureIndex] = useState(0);

  const getIcon = (feature: Feature): IconDefinition | undefined => {
    switch(feature.id) {
      case 'periodic': return faRepeat;
      case 'group': return faUsers;
      case 'payments': return faCreditCard;
      case 'contact': return faEnvelope;
      case 'analytics': return faChartLineUp;
      default: return undefined;
    }
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        const ratio = entry.intersectionRatio;
        setOpacity(ratio);
      },
      {
        threshold: Array.from({ length: 101 }, (_, i) => i / 400),
      }
    );

    if (ref.current) observer.observe(ref.current);
    return () => {
      if (ref.current) observer.unobserve(ref.current);
    };
  }, []);

  return (
    <div className="relative" ref={ref} id="features">
      <div className="bg-gradient-to-b from-white via-slate-50 to-white py-24 -mt-16">
        <div>
          <h2 className="text-center text-base/7 font-semibold text-primary-700">{t('landing.features.header')}</h2>
          <p className="mx-auto mt-2 max-w-2xl text-balance text-center text-4xl font-semibold tracking-tight text-gray-950 lg:text-5xl">
            {t('landing.features.slogan')}
          </p>
        </div>
        <svg
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          aria-hidden="true"
          style={{ opacity: opacity }}
          className="sticky top-0 inset-y-0 right-0 hidden h-dvh w-full fill-primary-050 lg:block"
        >
          <polygon points="100,0 50,70 50,70 100,100" />
        </svg>
        <div className="mt-[-100dvh] hidden lg:block"></div>
        <svg
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          aria-hidden="true"
          style={{ opacity: opacity }}
          className="sticky top-0 inset-y-0 right-0 hidden h-dvh w-full fill-secondary-050 lg:block"
        >
          <polygon points="0,0 30,40 30,40 0,100" />
        </svg>
        <div className="mt-[-100dvh] hidden lg:block"></div>
        <PhoneMiniature>
          {features.map((feature, i) => {
            const featureTranslation = useEntityTranslation<Feature, FeatureTranslation>();
            const currentTranslation = featureTranslation.getCurrentTranslation(feature);
            return (
              <img
                key={i}
                id={`image-feature-${i}`}
                src={currentTranslation?.image}
                alt={currentTranslation?.title as string}
                className={twMerge(
                  "absolute inset-0 size-full w-full lg:h-full object-cover transition-opacity duration-300",
                  activeFeatureIndex === i ? "opacity-100" : "opacity-0"
                )} />
            );
          })}
        </PhoneMiniature>
        <div className="mt-[-100dvh] hidden lg:block"></div>
        <div className="mx-auto max-w-2xl lg:max-w-7xl px-6 lg:px-8 flex flex-col lg:items-end">
          <div className="lg:w-2/3 relative overflow-hidden">
            {features.map((feature, i) => {
              const featureTranslation = useEntityTranslation<Feature, FeatureTranslation>();
              const currentTranslation = featureTranslation.getCurrentTranslation(feature);
              return (
                <FeatureDisplay
                  key={i}
                  id={`feature-${feature.id}`}
                  title={currentTranslation?.title}
                  slogan={currentTranslation?.slogan}
                  description={currentTranslation?.description}
                  icon={getIcon(feature)}
                  bulletpoints={currentTranslation?.bulletpoints}
                  onVisible={() => setActiveFeatureIndex(i)}
                />
              )
            })}
          </div>
        </div>
        <div className="h-[50dvh] lg:hidden"></div>
      </div>
    </div>
  );
}

function PhoneMiniature({ children }: { children: React.ReactNode }) {
  const ref = useRef(null);

  return (
    <div className="lg:h-dvh mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8 sticky -top-[-60%] lg:top-[0%] flex flex-row items-center z-20">
      <PhoneFrame className="h-[40dvh] lg:h-[66dvh]" ref={ref}>
        {children}
      </PhoneFrame>
    </div>
  );
}

export function PhoneFrame({ children, className, ref }: { children: React.ReactNode, className?: string, ref?: React.RefObject<HTMLDivElement> }) {
  return (
    <div className={twMerge("relative aspect-[9/19]", className)} ref={ref}>
      <div className="absolute inset-0 overflow-hidden rounded-[24px] md:rounded-[32px] lg:rounded-[42px] border-4 md:border-6 lg:border-8 border-gray-700 bg-gray-900 shadow-2xl text-white">
        <div className="relative size-full inset-0">
          {children}
        </div>
      </div>
    </div>
  )
}