import { useEffect } from "react";
import { useParams } from "react-router-dom";
import BentoGrid from "src/components/Landing/BentoGrid";
import CallToAction from "src/components/Landing/CallToAction";
import { ContactForm } from "src/components/Landing/Contact";
import { Faqs } from "src/components/Landing/Faq";
import Features from "src/components/Landing/Features";
import Hero from "src/components/Landing/Hero";
import { MobileBanner } from "src/components/Landing/MobileBanner";
import Newsletter from "src/components/Landing/Newsletter";
import Pricing from "src/components/Landing/Pricing";

export default function Landing() {
  const { section } = useParams();

  useEffect(() => {
    if (section) {
      const element = document.getElementById(section);
      if (element) {
        setTimeout(() => element.scrollIntoView({ behavior: 'smooth' }), 100);
      }
    } else {
      setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 100);
    }
  }, [section]);

  return (
    <>
      <Hero />
      <BentoGrid />
      <Features />
      <CallToAction />
      <MobileBanner />
      <Pricing />
      <Newsletter />
      <Faqs />
      <ContactForm />
    </>
  )
}