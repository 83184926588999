import { useEffect, useState } from "react";
import { Button } from "../Button";
import { getLastRenewal, getDateOfRenewal } from "./subscriptionMethods";
import { useTranslation } from "react-i18next";
import { LicensePeriod, LicenseStatus, Subscription, SubscriptionRenewalRequest, SubscriptionsClient } from "src/api/licensing/Licensing";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import useLocalizedNavigate from "src/hooks/useNavigate";
import FormCheckbox from "../Form/FormCheckbox";

export interface SubscriptionPaymentButtonProps {
  subscription: Subscription;
  updateSubscription: (subscription: Subscription) => void;
  expiryDate?: Date;
}

const SubscriptionPaymentButton = (props: SubscriptionPaymentButtonProps) => {
  const { subscription, updateSubscription } = props;
  const { t, i18n } = useTranslation();
  const navigate = useLocalizedNavigate();
  const [renewalPeriod, setRenewalPeriod] = useState(LicensePeriod.Monthly);
  const [automatic, setAutomatic] = useState(false);

  const apiConfiguration = useApiConfiguration();
  const apiClient = new SubscriptionsClient(apiConfiguration);

  useEffect(() => {
    if (!subscription.period) return;
    setRenewalPeriod(subscription.period);
  }, [subscription.period]);

  const onClickPayment = () => {
    if (!lastRenewal?.isPaid) {
      navigate(`/payments/subscription/${subscription.id}/${automatic ? 'true' : 'false'}`);
      return;
    }
    apiClient
      .renewal(subscription.id!, {
        subscriptionId: subscription.id!,
        period: renewalPeriod,
      } as SubscriptionRenewalRequest)
      .then(response => {
        navigate(`/payments/subscription/${subscription.id}`);
        updateSubscription(response);
      })
      .catch(response => console.error(response));
  }

  const lastRenewal = getLastRenewal(subscription);
  const renewalDate = getDateOfRenewal((subscription.status === LicenseStatus.Active ? lastRenewal?.expiryDate : new Date) || new Date(), renewalPeriod);
  const renewalPrice = subscription.product?.prices?.find(p => p.period === renewalPeriod);


  return (
    <div className="p-5 flex-1">
      <h3 className="text-gray-500 mb-3">{t('payment.payment')}</h3>
      <h2 className="text-gray-700 text-xl pt-1">
        <Button color="secondary" onClick={onClickPayment}>{t('licensing.payForNext')} {renewalPeriod === LicensePeriod.Monthly ? t('enums.period.month') : t('enums.period.annual')}</Button>
      </h2>
      <div className="my-3">
        <FormCheckbox.Input
          id={`${subscription.id}-automatic`}
          onChange={(e) => setAutomatic(e.target.checked)}
          checked={automatic}
          placeholder={t('payment.automaticRenewal')}
        />
      </div>
      <h4 className="text-gray-400 text-sm mt-5">{t('payment.amount')}: {renewalPrice?.price?.toLocaleString(i18n.resolvedLanguage, { style: 'currency', currency: 'PLN' })}<br />{t('licensing.renewalTo')}: {renewalDate?.toLocaleDateString(i18n.resolvedLanguage, { dateStyle: 'medium' })}</h4>
      <h4
        className="text-primary-700 hover:text-primary-600 active:text-primary-400 text-sm mt-5 cursor-pointer"
        onClick={() => setRenewalPeriod(renewalPeriod === LicensePeriod.Monthly ? LicensePeriod.Annually : LicensePeriod.Monthly)}
      >
        {t('licensing.iWantToPay')} {renewalPeriod === LicensePeriod.Monthly ? t('licensing.payAnually') : t('licensing.payMonthly')}
      </h4>
    </div>
  );
};

export default SubscriptionPaymentButton;