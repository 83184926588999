import { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { ApplyForm } from 'src/components/ApplyForm';
import { Container } from 'src/components/Container';
import { MetaHead } from 'src/components/MetaHead';

export default function Apply() {
  const { t } = useTranslation();
  useEffect(() => {
    setTimeout(scrollToTop, 100);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <MetaHead title={t('apply.subheader')} description={t('apply.slogan')} />
      <div className="relative">
        <svg
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          aria-hidden="true"
          className="fixed top-0 inset-y-0 right-0 hidden h-dvh w-full fill-primary-700 opacity-10 lg:block -z-10"
        >
          <polygon points="100,0 50,70 50,70 100,100" />
        </svg>
        <svg
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          aria-hidden="true"
          className="fixed top-0 inset-y-0 right-0 hidden h-dvh w-full fill-secondary-500 opacity-10 lg:block -z-10"
        >
          <polygon points="0,0 60,40 60,40 0,100" />
        </svg>
        <div className="relative z-20">

          <Container className="pt-20 lg:pt-32 mb-16">
            <div className="max-w-2xl mx-auto lg:bg-white/60 p-4 lg:p-8 lg:backdrop-filter lg:backdrop-blur-lg lg:rounded-lg lg:shadow-2xl">
              <h2 className="text-center text-base/7 font-semibold text-secondary-500">{t('apply.header')}</h2>
              <p className="mx-auto mt-2 max-w-2xl text-balance text-center text-4xl font-semibold tracking-tight text-gray-950 lg:text-5xl">{t('apply.subheader')}</p>
              <h1 className="text-4xl text-secondary-500 mb-8"></h1>
              <h2 className="text-3xl text-gray-500 mb-8"></h2>
              <div className="max-w-7xl mx-auto mb-8">
                <ApplyForm />
              </div>
              <hr className="mb-8" />
              <p className="text-gray-500 mb-4">
                {t('apply.slogan')}
              </p>
            </div>
          </Container>
        </div>
      </div>
    </>
  )
}
