import { useTranslation } from "react-i18next"

export default function BentoGrid() {
  const { t, i18n } = useTranslation();
  return (
    <div className="bg-gradient-to-b from-white via-slate-50 to-white min-h-dvh py-24 lg:py-32 -mt-16" id="bento">
      <div className="mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8">
        <h2 className="text-center text-base/7 font-semibold text-secondary-500">{t('landing.bento.header')}</h2>
        <p className="mx-auto mt-2 max-w-2xl text-balance text-center text-4xl font-semibold tracking-tight text-gray-950 lg:text-5xl whitespace-pre-line">
          {t('landing.bento.slogan')}
        </p>
        <div className="mt-10 grid gap-4 lg:mt-16 lg:grid-cols-3 lg:grid-rows-2">
          <div className="relative lg:row-span-2">
            <div className="absolute inset-px rounded-lg bg-white max-lg:rounded-b-[2rem] lg:rounded-r-[2rem]"></div>
            <div className="relative flex h-full flex-col overflow-hidden lg:justify-between rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-b-[calc(2rem+1px)] lg:rounded-r-[calc(2rem+1px)]">
              <div className="px-8 pb-3 pt-8 lg:px-10 lg:pb-0 lg:pt-10">
                <p className="mt-2 text-lg font-medium tracking-tight text-secondary-500 max-lg:text-center">
                  {t('landing.bento.items.0.title')}
                </p>
                <p className="mt-2 max-w-lg text-sm/6 text-gray-600 max-lg:text-center whitespace-pre-line">
                  {t('landing.bento.items.0.description')}
                </p>
              </div>
              <div className="relative min-h-[30rem] [container-type:inline-size] w-full lg:w-auto max-lg:mx-auto max-lg:max-w-sm">
                <img src={`/images/screenshots/${i18n.resolvedLanguage}/desktop/booking.webp`} className="absolute scale-150 -right-1/2 top-1/4 shadow-2xl rounded-md" />
              </div>
            </div>
            <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-b-[2rem] lg:rounded-r-[2rem]"></div>
          </div>
          <div className="relative lg:row-span-2">
            <div className="absolute inset-px rounded-lg bg-white lg:rounded-l-[2rem]"></div>
            <div className="relative flex h-full flex-col overflow-hidden lg:justify-between rounded-[calc(theme(borderRadius.lg)+1px)] lg:rounded-l-[calc(2rem+1px)]">
              <div className="px-8 pb-3 pt-8 lg:px-10 lg:pb-0 lg:pt-10">
                <p className="mt-2 text-lg font-medium tracking-tight text-secondary-500 max-lg:text-center">
                  {t('landing.bento.items.1.title')}
                </p>
                <p className="mt-2 max-w-lg text-sm/6 text-gray-600 max-lg:text-center whitespace-pre-line">
                  {t('landing.bento.items.1.description')}
                </p>
              </div>
              <div className="relative min-h-[30rem] [container-type:inline-size] w-full lg:w-auto max-lg:mx-auto max-lg:max-w-sm">
                <img src={`/images/screenshots/${i18n.resolvedLanguage}/desktop/website.webp`} className="absolute scale-150 -left-1/2 top-1/4 shadow-2xl rounded-md" />
              </div>
            </div>
            <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 lg:rounded-l-[2rem]"></div>
          </div>
          <div className="relative max-lg:row-start-1">
            <div className="absolute inset-px rounded-lg bg-white max-lg:rounded-t-[2rem]"></div>
            <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-t-[calc(2rem+1px)]">
              <div className="px-8 pt-8 lg:px-10 lg:pt-10">
                <p className="mt-2 text-lg font-medium tracking-tight text-primary-700 max-lg:text-center">{t('landing.bento.items.2.title')}</p>
                <p className="mt-2 max-w-lg text-sm/6 text-gray-600 max-lg:text-center">
                  {t('landing.bento.items.2.description')}
                </p>
              </div>
              <div className="relative min-h-[10rem] w-full grow [container-type:inline-size] max-lg:mx-auto max-lg:max-w-sm">
                <div className="absolute inset-x-10 bottom-0 top-10 overflow-hidden rounded-t-[5cqw] bg-gray-200 shadow-2xl">
                  <div className="p-1.5 flex flex-row">
                    <div className="p-1 flex flex-row gap-x-1 mr-auto">
                      <div className="size-3 bg-red-500 rounded-full"></div>
                      <div className="size-3 bg-yellow-500 rounded-full"></div>
                      <div className="size-3 bg-green-500 rounded-full"></div>
                    </div>
                    <div>
                      <div className="h-5 w-32 bg-gray-300 rounded-full"></div>
                    </div>
                    <div className="p-1 flex flex-row gap-x-1 ml-auto">
                      <div className="size-3 bg-red-500 rounded-full opacity-0"></div>
                      <div className="size-3 bg-yellow-500 rounded-full opacity-0"></div>
                      <div className="size-3 bg-green-500 rounded-full opacity-0"></div>
                    </div>
                  </div>
                  <div className="absolute left-0 w-full bottom-0 top-8 overflow-hidden bg-gray-50 shadow-2xl">

                  </div>
                </div>
              </div>
            </div>
            <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-t-[2rem]"></div>
          </div>
          <div className="relative max-md:row-start-2 max-lg:row-start-1 lg:col-start-3 lg:row-start-2">
            <div className="absolute inset-px rounded-lg bg-white"></div>
            <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)]">
              <div className="px-8 pt-8 lg:px-10 lg:pt-10">
                <p className="mt-2 text-lg font-medium tracking-tight text-primary-700 max-lg:text-center">{t('landing.bento.items.3.title')}</p>
                <p className="mt-2 max-w-lg text-sm/6 text-gray-600 max-lg:text-center">
                  {t('landing.bento.items.3.description')}
                </p>
              </div>
              <div className="relative min-h-[10rem] w-full grow [container-type:inline-size] max-lg:mx-auto max-lg:max-w-sm">
                <div className="absolute inset-x-10 bottom-0 top-10 overflow-hidden rounded-t-[12cqw] border-x-[3cqw] border-t-[3cqw] border-gray-700 bg-gray-900 shadow-2xl">
                  <div className="absolute top-1/4 left-1/3 w-1/3 bg-gray-700 h-1/6 rounded-full" />
                </div>
              </div>
            </div>
            <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5"></div>
          </div>
        </div>
      </div>
    </div>
  )
}
