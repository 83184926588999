import { Container } from 'src/components/Container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons'
import { useTranslation } from 'react-i18next'

export function MobileBanner() {
  const { i18n, t } = useTranslation();
  return (
    <section
      id="mobile"
      className="relative overflow-hidden pt-4 md:pt-24 lg:pt-48 pb-8 lg:pb-16"
    >
      <Container>
        <div className="relative">
          <div className="absolute bg-primary-700/75 -inset-0 -left-3 -top-3 lg:-left-8 lg:-top-8 size-full rounded-xl"></div>
          <div className="absolute bg-secondary-500/75 -inset-0 left-3 top-3 lg:left-8 lg:top-8 size-full rounded-xl"></div>
          <div className="absolute bg-primary-950 -inset-0 size-full rounded-xl shadow-2xl"></div>
          <div className="px-8 py-4 lg:px-24 lg:py-16">
            <div className="relative z-20 mx-auto text-left">
              <div className="lg:max-w-2xl xl:max-w-3xl">
                <h2 className="font-display text-4xl lg:text-6xl tracking-tight font-branding text-white">
                  {t('landing.mobile.header')}
                </h2>
                <div className="my-8 text-slate-200 text-left grid grid-cols-1 gap-y-2 text-lg">
                  <div>
                    <FontAwesomeIcon icon={faCheckCircle} className="text-secondary-500 mr-1" /> {t('landing.mobile.descriptions.0')}
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faCheckCircle} className="text-secondary-500 mr-1" /> {t('landing.mobile.descriptions.1')}
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faCheckCircle} className="text-secondary-500 mr-1" /> {t('landing.mobile.descriptions.2')}
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faCheckCircle} className="text-secondary-500 mr-1" /> {t('landing.mobile.descriptions.3')}
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faCheckCircle} className="text-secondary-500 mr-1" /> {t('landing.mobile.descriptions.4')}
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faCheckCircle} className="text-secondary-500 mr-1" /> {t('landing.mobile.descriptions.5')}
                  </div>
                </div>
                <p className="mt-8 text-slate-300 text-xl">
                  {t('landing.mobile.slogan')}
                </p>
              </div>
              <div className="text-center md:text-start text-xl lg:text-2xl xl:text-3xl">
                <div className="flex gap-5 my-5 justify-center lg:justify-normal">
                  <div>
                    <a href="https://apps.apple.com/pl/app/redini-aplikacja-je%C5%BAdziecka/id6737122460" target="_blank" rel="noreferrer">
                      <img className="h-10 md:h-16 w-auto transition-all hover:scale-105 active:scale-95" style={{ margin: '1px' }} src={`/images/mobile/${i18n?.resolvedLanguage}/app-store.webp`} alt={t('ui.getFromAppStore')} title={t('ui.getFromAppStore')} />
                    </a>
                  </div>
                  <div>
                    <a href="https://play.google.com/store/apps/details?id=com.h0pe.rediniapp" target="_blank" rel="noreferrer">
                      <img className="h-10 md:h-16 w-auto transition-all hover:scale-105 active:scale-95" src={`/images/mobile/${i18n?.resolvedLanguage}/google-play.webp`} alt={t('ui.getFromGooglePlay')} title={t('ui.getFromGooglePlay')} />
                    </a>
                  </div>
                </div>
                <div className="flex flex-col mt-8">
                  <p className="text-sm text-slate-500">
                    {t('landing.mobile.androidSupport')}
                  </p>
                  <p className="text-sm text-slate-500">
                    {t('landing.mobile.iOSSupport')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <div className="hidden lg:block absolute z-10 top-1/4 right-24 xl:right-32 2xl:right-48 w-1/4 max-w-80">
        <img src={`/images/screenshots/${i18n.resolvedLanguage}/mobiles.webp`} />
      </div>
    </section>
  )
}
