import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { twMerge } from 'tailwind-merge'

export default function Selector({
  children, button, anchor, withoutChevron
}: {
  children?: React.ReactNode | string,
  button?: React.ReactNode | string,
  withoutChevron?: boolean,
  anchor?: "top" | "bottom"
}) {
  return (
    <div className="relative inline-block text-left">
      <Popover>
        {({ open }: { open?: boolean }) => (
          <>
            <PopoverButton className="text-sm font-medium text-gray-700 hover:text-gray-900 flex hover:scale-105 active:scale-95 transition-all">
              {button}
              {!withoutChevron && <FontAwesomeIcon icon={faChevronDown} className={twMerge('w-5 h-5 ml-2 transition-transform', open ? 'rotate-180' : '')} aria-hidden="true" />}
            </PopoverButton>
            <PopoverPanel
              transition
              anchor={anchor ?? "bottom"}
              className="absolute z-50 min-w-32 max-w-72 mt-2 origin-top-right bg-white/60 backdrop-filter backdrop-blur-lg divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none transition-all ease-out data-[closed]:scale-95 data-[closed]:opacity-0"
            >
              <div className="p-2 max-h-64">
                {children}
              </div>
            </PopoverPanel>
          </>
        )}
      </Popover>
    </div>
  );
}