import { twMerge } from "tailwind-merge";
import { Feature, FeatureTranslation, Section, SectionTranslation, Tier, TierTranslation } from "./DataTypes";
import useEntityTranslation from "src/hooks/useEntityTranslation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/pro-light-svg-icons";

function TierHeader({tier}: {tier: Tier}) {
  const tierTranslation = useEntityTranslation<Tier, TierTranslation>();
  const currentTranslation = tierTranslation.getCurrentTranslation(tier);
  return (
      <div key={tier.id} aria-hidden="true" className="-mt-px">
        <div
          className={twMerge(
            tier.featured ? 'border-secondary-600' : 'border-transparent',
            'border-t-2 pt-10',
          )}
        >
          <p
            className={twMerge(
              tier.featured ? 'text-secondary-600' : 'text-gray-900',
              'text-sm/6 font-semibold',
            )}
          >
          {currentTranslation?.name}
        </p>
        <p className="mt-1 text-sm/6 text-gray-600">{currentTranslation?.description}</p>
        </div>
      </div>
    );
}

function ComparisonFeatureValue({feature, tier}: {feature: Feature, tier: Tier}) {
  return (
    <td key={tier.id} className="relative w-1/4 px-4 py-0 text-center">
      <span className="relative size-full py-3">
        {typeof feature.tiers[tier.id] === 'string' ? (
          <span
            className={twMerge(
              tier.featured ? 'font-semibold text-secondary-600' : 'text-gray-900',
              'text-sm/6',
            )}
          >
            {feature.tiers[tier.id]}
          </span>
        ) : (
          <>
            {feature.tiers[tier.id] === true ? (
              <FontAwesomeIcon icon={faCheck} aria-hidden="true" className="mx-auto size-5 text-secondary-600" />
            ) : (
              <FontAwesomeIcon icon={faTimes} aria-hidden="true" className="mx-auto size-5 text-gray-400" />
            )}

            <span className="sr-only">
              {feature.tiers[tier.id] === true ? 'Yes' : 'No'}
            </span>
          </>
        )}
      </span>
    </td>
  );
}

function ComparisonFeature({section, feature, tiers, id}: {section: Section, feature: Feature, tiers: Tier[], id: number}) {
  const featureTranslation = useEntityTranslation<Feature, FeatureTranslation>();
  const currentTranslation = featureTranslation.getCurrentTranslation(feature);
  return (
    <tr key={feature.id}>
      <th scope="row" className="w-1/4 py-3 pr-4 text-left text-sm/6 font-normal text-gray-900">
        {currentTranslation?.name}
        {id !== section.features.length - 1 ? (
          <div className="absolute inset-x-8 mt-3 h-px bg-gray-200" />
        ) : null}
      </th>
      {tiers.filter(t => t.comparison).map((tier) => <ComparisonFeatureValue key={tier.id} feature={feature} tier={tier} />)}
    </tr>
  )
}

function TierScreenReader({tier}: {tier: Tier}) {
  const tierTranslation = useEntityTranslation<Tier, TierTranslation>();
  const currentTranslation = tierTranslation.getCurrentTranslation(tier);
  return (
    <th key={tier.id} scope="col">
      <span className="sr-only">{currentTranslation?.name} tier</span>
    </th>
  )
}

function ComparisonSection({ section, tiers }: { section: Section, tiers: Tier[] }) {
  const sectionTranslation = useEntityTranslation<Section, SectionTranslation>();
  const currentTranslation = sectionTranslation.getCurrentTranslation(section);
  return (
    <div key={section.id} id={`pricing-${section.id}`} className="pt-24 -mt-16">
      <h3 className="text-sm/6 font-semibold text-gray-900">{currentTranslation?.name}</h3>
      <div className="relative -mx-8 mt-10">
        <div
          aria-hidden="true"
          className="absolute inset-x-8 inset-y-0 grid grid-cols-3 gap-x-8 before:block"
        >
          <div className="size-full rounded-lg bg-white shadow-sm" />
          <div className="size-full rounded-lg bg-white shadow-sm" />
        </div>

        <table className="relative w-full border-separate border-spacing-x-8">
          <thead>
            <tr className="text-left">
              <th scope="col">
                <span className="sr-only">Feature</span>
              </th>
              {tiers.filter(t => t.comparison).map((tier) => <TierScreenReader key={tier.id} tier={tier} />)}
            </tr>
          </thead>
          <tbody>
            {section.features.map((feature, i) => <ComparisonFeature key={feature.id} section={section} feature={feature} tiers={tiers} id={i} />)}
          </tbody>
        </table>
        <div
          aria-hidden="true"
          className="pointer-events-none absolute inset-x-8 inset-y-0 grid grid-cols-3 gap-x-8 before:block"
        >
          {tiers.filter(t => t.comparison).map((tier) => (
            <div
              key={tier.id}
              className={twMerge(
                tier.featured ? 'ring-2 ring-secondary-600' : 'ring-1 ring-gray-900/10',
                'rounded-lg',
              )}
            />
          ))}
        </div>
      </div>
    </div>
  )
}


export default function DesktopComparison({tiers, sections}: {tiers: Tier[], sections: Section[]}) {
  return (
    <section aria-labelledby="comparison-heading" className="hidden md:block">
      <h2 id="comparison-heading" className="sr-only">
        Porównanie funkcjonalności
      </h2>

      <div className="grid grid-cols-3 gap-x-8 border-t border-gray-900/10 before:block bg-slate-50 px-6 lg:px-8">
        {tiers.map((tier) => <TierHeader key={tier.id} tier={tier} />)}
      </div>
      <div className="bg-slate-50 px-6 lg:px-8 rounded-lg pb-8">
        {sections.map((section) => <ComparisonSection key={section.id} section={section} tiers={tiers} />)}
      </div>
    </section>
  )
}