import { useState, useEffect, createContext } from "react";
import { RegionContextType } from "./state";
import { detectRegion, detectRegionByGeoIp, EnabledRegions } from "src/components/i18n/clientRegion";

export const RegionContext = createContext<RegionContextType | undefined>(undefined);

const getCurrentLocalStorage = () => {
  return localStorage.getItem("region") || undefined;
}

export const RegionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [region, setRegion] = useState<string>(() => {
    const fromLocalStorage = getCurrentLocalStorage();
    if (fromLocalStorage) {
      return fromLocalStorage;
    }
    const navigatorRegion = detectRegion();
    detectRegionByGeoIp()
      .then(response => {
        if (!response) return;
        setRegion(response);
      })
    return navigatorRegion;
  });

  useEffect(() => {
    if (region) return;
    setRegion(detectRegion());
  }, [navigator.language]);

  useEffect(() => {
    localStorage.setItem("region", region);
  }, [region]);

  const data = EnabledRegions.find((r) => r.countryCode === region);

  return (
    <RegionContext.Provider value={{ region, setRegion, data }}>
      {children}
    </RegionContext.Provider>
  );
};