import { useContext } from "react";
import { RegionContext } from "src/store/region/context";


export const useRegion = () => {
  const context = useContext(RegionContext);
  if (!context) {
    throw new Error('useRegion must be used within a RegionProvider');
  }
  return context;
};