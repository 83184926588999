/* eslint-disable @typescript-eslint/no-explicit-any */
import { faChevronCircleDown, faChevronCircleUp, faExternalLink } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { Container } from 'src/components/Container'
import Data from 'src/data/Faq.json';
import useEntityTranslation from 'src/hooks/useEntityTranslation';
import ContentParse from './../Cms/ContentParse';
import LocalizedLink from './../Router/LocalizedLink';
import { useTranslation } from 'react-i18next';

export interface FaqData {
  id: string;
  translations: FaqDataTranslation[];
}

export interface FaqDataTranslation {
  culture: string;
  question: string;
  answer: string;
}

export function Faqs() {
  const { t } = useTranslation();
  const data = Data as FaqData[];
  const faqTranslation = useEntityTranslation<FaqData, FaqDataTranslation>();
  return (
    <Container className="relative pt-20 lg:pt-24" id="faq">
      <h2
        id="faq-title"
        className="font-display text-4xl sm:text-5xl text-primary-700 tracking-tight mb-16"
      >
        {t('faq.header')}:
      </h2>
      <div>
        {data.map((faq, i) => (
          <div key={i} className="rounded-lg border border-gray-200 py-2 px-5 mb-3 bg-white" id={`${i}`}>
            <Disclosure>
              {({ open }: any) => (
                <>
                  <div className="w-full">
                    <DisclosureButton className="py-2 font-medium w-full text-left text-lg flex justify-between">
                      <span>{faqTranslation.getCurrentTranslation(faq)?.question}</span>
                      <LocalizedLink to={`/faq/${faq.id}-${faqTranslation.getCurrentTranslation(faq)?.question.toLocaleLowerCase().replace(' ', '-').replace('?', '').replace(',', '').replace('.', '')}`} className="ml-auto">
                        <FontAwesomeIcon icon={faExternalLink} className="h-6 text-primary-700 opacity-25" />
                      </LocalizedLink>
                      <FontAwesomeIcon icon={open ? faChevronCircleUp : faChevronCircleDown} className="ml-3 h-6 text-secondary-500" />
                    </DisclosureButton>
                    <DisclosurePanel className="text-gray-700">
                      <p className="p-4"><ContentParse>{faqTranslation.getCurrentTranslation(faq)?.answer}</ContentParse></p>
                    </DisclosurePanel>
                  </div>
                </>
              )}
            </Disclosure>
          </div>
        ))}
      </div>
      <p className="mt-8 text-lg tracking-tight text-slate-700 text-center whitespace-pre-line">
        {t('faq.slogan')}
      </p>
    </Container>
  )
}
