'use client'

import { faCheck } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Radio, RadioGroup } from "@headlessui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next"
import useEntityTranslation from "src/hooks/useEntityTranslation";
import { twMerge } from "tailwind-merge";
import { Frequency, Tier, TierTranslation, PriceTranslation, Price, Section } from "./Pricing/DataTypes";
import MobileComparison from "./Pricing/MobileComparison";
import DesktopComparison from "./Pricing/DesktopComparison";
import { useRegion } from "src/hooks/useRegion";
import Tiers from "./../../data/pricing/Tiers.json";
import Sections from "./../../data/pricing/Comparison.json";
import LocalizedLink from "../Router/LocalizedLink";


const frequencies: Frequency[] = [
  { value: 'monthly', label: 'pricing.monthly', priceSuffix: 'pricing.monthly' },
  { value: 'annually', label: 'pricing.annually', priceSuffix: 'pricing.annually' },
]

const tiers = Tiers as Tier[];
const sections = Sections as Section[];

function PricingTier({tier, frequency} : {tier: Tier, frequency: Frequency}) {
  const { t, i18n } = useTranslation();
  const { data } = useRegion()
  const price = tier.prices?.find(p => p.key === frequency.value && p.currency === data?.currency) || tier.prices?.[0];
  const tierTranslation = useEntityTranslation<Tier, TierTranslation>();
  const priceTranslation = useEntityTranslation<Price, PriceTranslation>();
  const currentTranslation = tierTranslation.getCurrentTranslation(tier);
  const currentPriceTranslation = priceTranslation.getCurrentTranslation(price);
  if (!price) return;
  return ((
    <div
      key={tier.id}
      className={twMerge(
        tier.featured ? 'bg-primary-950 ring-gray-900' : 'bg-slate-50 ring-gray-200',
        'rounded-3xl p-6 ring-1 xl:p-8 flex-1',
      )}
    >
      <h3
        id={tier.id}
        className={twMerge(tier.featured ? 'text-white' : 'text-gray-900', 'text-lg/8 font-semibold')}
      >
        {currentTranslation?.name}
      </h3>
      <p className={twMerge(tier.featured ? 'text-gray-300' : 'text-gray-600', 'mt-4 text-sm/6')}>
        {currentTranslation?.description}
      </p>
      <p className="mt-6 flex items-baseline gap-x-1">
        <span
          className={twMerge(
            tier.featured ? 'text-white' : 'text-gray-900',
            'text-3xl font-semibold tracking-tight',
          )}
        >
          {currentPriceTranslation?.display ?? price.value?.toLocaleString(i18n.resolvedLanguage, { style: 'currency', currency: price.currency, minimumFractionDigits: 0 })}
        </span>
        {price.key ? (
          <span
            className={twMerge(tier.featured ? 'text-gray-300' : 'text-gray-600', 'text-sm/6 font-semibold')}
          >
            /{t(frequency.priceSuffix)}
          </span>
        ) : null}
      </p>
      <LocalizedLink
        to={tier.href}
        aria-describedby={tier.id}
        className={twMerge(
          tier.featured
            ? 'bg-white/10 text-white hover:bg-white/20 focus-visible:outline-white'
            : 'bg-primary-700 text-white shadow-sm hover:bg-primary-600 focus-visible:outline-primary-700',
          'mt-6 block rounded-md px-3 py-2 text-center text-sm/6 font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 w-full',
        )}
      >
        {currentTranslation?.cta}
      </LocalizedLink>
      <ul
        role="list"
        className={twMerge(
          tier.featured ? 'text-gray-300' : 'text-gray-600',
          'mt-8 space-y-3 text-sm/6 xl:mt-10',
        )}
      >
        {currentTranslation?.features.map((feature) => (
          <li key={feature} className="flex gap-x-3">
            <FontAwesomeIcon
              icon={faCheck}
              aria-hidden="true"
              className={twMerge(tier.featured ? 'text-white' : 'text-secondary-600', 'h-6 w-5 flex-none')}
            />
            {feature}
          </li>
        ))}
      </ul>
    </div>
  ))
}

export default function Pricing() {
  const [frequency, setFrequency] = useState(frequencies[0]);
  const { t } = useTranslation();

  return (
    <div className="bg-white pt-20 lg:pt-24" id="pricing">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-base/7 font-semibold text-primary-700">{t('landing.pricing.leading')}</h2>
          <p className="mt-2 text-balance text-5xl font-semibold tracking-tight text-gray-900 lg:text-6xl">
            {t('landing.pricing.header')}
          </p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-pretty text-center text-lg font-medium text-gray-600 lg:text-xl/8">
          {t('landing.pricing.slogan')}
        </p>
        <div className="mt-16 flex justify-center">
          <fieldset aria-label="Payment frequency">
            <RadioGroup
              value={frequency}
              onChange={setFrequency}
              className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center font-semibold ring-1 ring-inset ring-gray-200"
            >
              {frequencies.map((option) => (
                <Radio
                  key={option.value}
                  value={option}
                  className="cursor-pointer rounded-full px-2.5 py-1 text-gray-500 data-[checked]:bg-secondary-500 data-[checked]:text-white"
                >
                  {t(option.label)}
                </Radio>
              ))}
            </RadioGroup>
          </fieldset>
        </div>
        <div className="isolate mx-auto mt-10 max-w-md gap-4 lg:mx-0 md:max-w-none grid grid-cols-1 md:grid-cols-3">
          {tiers.map((tier) => <PricingTier key={tier.id} tier={tier} frequency={frequency} />)}
        </div>
      </div>
      <ReferralProgram />
      <Comparison />
    </div>
  )
}

function ReferralProgram() {
  const { t } = useTranslation();
  return (
    <div className="mx-auto max-w-7xl px-6 lg:px-8 pt-16">
      <div className="flex flex-col md:flex-row bg-slate-900 p-6 lg:p-8 rounded-xl gap-8 shadow-2xl">
        <div className="md:h-full lg:h-auto lg:w-1/2">
          <img src="/images/photos/error.webp" className="rounded-lg" />
        </div>
        <div>
          <h2 className="text-balance text-3xl font-semibold tracking-tight text-secondary-500 lg:text-4xl">
            {t('landing.referral.banner.header')}
          </h2>
          <p className="mt-6 text-pretty text-lg/8 text-gray-300">
            {t('landing.referral.banner.description')}
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6 md:justify-start">
            <LocalizedLink to="/referral-program" className="text-sm/6 font-semibold text-white">
              {t('landing.referral.banner.link')} <span aria-hidden="true">→</span>
            </LocalizedLink>
          </div>
        </div>
      </div>
    </div>
  )
}

function Comparison() {
  const comparisonTiers = tiers.filter(t => t.comparison);
  return (

    <div className="relative bg-white">
      <div className="mx-auto max-w-7xl pt-24 lg:pt-32">
        <MobileComparison tiers={comparisonTiers} sections={sections} />
        <DesktopComparison tiers={comparisonTiers} sections={sections} />
      </div>
    </div>
  )
}