import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tier, Section, Feature, TierTranslation, FeatureTranslation, SectionTranslation } from "./DataTypes";
import { faCheck, faTimes } from "@fortawesome/pro-light-svg-icons";
import { twMerge } from "tailwind-merge";
import useEntityTranslation from "src/hooks/useEntityTranslation";

function ComparisonFeature({ feature, tier } : {feature: Feature, tier: Tier}) {
  const featureTranslation = useEntityTranslation<Feature, FeatureTranslation>();
  const currentTranslation = featureTranslation.getCurrentTranslation(feature);
  return (
    <div
      key={feature.id}
      className="flex items-center justify-between px-4 py-3 lg:grid lg:grid-cols-2 lg:px-0"
    >
      <dt className="pr-4 text-gray-600">{currentTranslation?.name}</dt>
      <dd className="flex items-center justify-end lg:justify-center lg:px-4">
        {typeof feature.tiers[tier.id] === 'string' ? (
          <span
            className={tier.featured ? 'font-semibold text-secondary-600' : 'text-gray-900'}
          >
            {feature.tiers[tier.id]}
          </span>
        ) : (
          <>
            {feature.tiers[tier.id] === true ? (
              <FontAwesomeIcon icon={faCheck} aria-hidden="true" className="mx-auto size-5 text-secondary-600" />
            ) : (
              <FontAwesomeIcon icon={faTimes} aria-hidden="true" className="mx-auto size-5 text-gray-400" />
            )}

            <span className="sr-only">
              {feature.tiers[tier.id] === true ? 'Yes' : 'No'}
            </span>
          </>
        )}
      </dd>
    </div>
  )
}

function ComparisonSection({section, tier}: {section: Section, tier: Tier}) {
  const sectionTranslation = useEntityTranslation<Section, SectionTranslation>();
  const currentTranslation = sectionTranslation.getCurrentTranslation(section);
  return (
    <div key={section.id} id={`pricing-${tier.id}-${section.id}`} className="pt-20 -mt-10">
      <h4 className="text-sm/6 font-semibold text-gray-900">{currentTranslation?.name}</h4>
      <div className="relative mt-6">
        {/* Fake card background */}
        <div
          aria-hidden="true"
          className="absolute inset-y-0 right-0 hidden w-1/2 rounded-lg bg-white shadow-sm lg:block"
        />

        <div
          className={twMerge(
            tier.featured ? 'ring-2 ring-secondary-600' : 'ring-1 ring-gray-900/10',
            'relative rounded-lg bg-white shadow-sm lg:rounded-none lg:bg-transparent lg:shadow-none lg:ring-0',
          )}
        >
          <dl className="divide-y divide-gray-200 text-sm/6">
            {section.features.map((feature) => <ComparisonFeature key={feature.id} feature={feature} tier={tier} />)}
          </dl>
        </div>

        {/* Fake card border */}
        <div
          aria-hidden="true"
          className={twMerge(
            tier.featured ? 'ring-2 ring-secondary-600' : 'ring-1 ring-gray-900/10',
            'pointer-events-none absolute inset-y-0 right-0 hidden w-1/2 rounded-lg lg:block',
          )}
        />
      </div>
    </div>
  )
}

function ComparisonTier({tier, sections} : {tier: Tier, sections: Section[]}) {
  const tierTranslation = useEntityTranslation<Tier, TierTranslation>();
  const currentTranslation = tierTranslation.getCurrentTranslation(tier);
  return (
    <div key={tier.id} className="border-t border-gray-900/10 bg-slate-50 px-6 lg:px-8">
      <div
        className={twMerge(
          tier.featured ? 'border-secondary-600' : 'border-transparent',
          '-mt-px w-72 border-t-2 pt-10 md:w-80',
        )}
      >
        <h3
          className={twMerge(
            tier.featured ? 'text-secondary-600' : 'text-gray-900',
            'text-sm/6 font-semibold',
          )}
        >
          {currentTranslation?.name}
        </h3>
        <p className="mt-1 text-sm/6 text-gray-600">{currentTranslation?.description}</p>
      </div>

      <div className="">
        {sections.map((section) => <ComparisonSection key={section.id} section={section} tier={tier} />)}
      </div>
    </div>
  )
}

export default function MobileComparison({tiers, sections} : {tiers: Tier[], sections: Section[]}) {
  return (
    <section aria-labelledby="mobile-comparison-heading" className="md:hidden">
      <h2 id="mobile-comparison-heading" className="sr-only">
        Porównanie funkcjonalności
      </h2>

      <div className="mx-auto max-w-2xl space-y-16">
        {tiers.filter(t => t.comparison).map((tier) => <ComparisonTier key={tier.id} tier={tier} sections={sections} />)}
      </div>
    </section>
  )
}