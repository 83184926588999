import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import FrontLayout from './layouts/FrontLayout';
import RouterErrorBoundary from './components/Feedback/RouterErrorBoundary';
import SignIn from './views/Auth/SignIn';
import Availability from './views/Index/Availability';
import Apply from './views/Index/Apply';
import Page from './views/Cms/Page';
import Newsfeed from './views/Cms/Newsfeed';
import NewsfeedPost from './views/Cms/NewsfeedPost';
import HelpIndex from './views/Cms/HelpIndex';
import GalleriesIndex from './views/Cms/GalleriesIndex';
import HelpPage from './views/Cms/HelpPage';
import Gallery from './views/Cms/Gallery';
import SlimLayout from './layouts/SlimLayout';
import UserLayout from './layouts/UserLayout';
import MyAccountView from './views/User/MyAccount';
import SubscriptionView from './views/User/Subscription';
import BillingInfoView from './views/User/BillingInfo';
import InvoicesView from './views/User/Invoices';
import PaymentDataView from './views/User/PaymentData';
import SubscriptionPaymentView from './views/Payments/Subscription';
import PaymentsLayout from './layouts/PaymentLayout';
import ReturnPaymentView from './views/Payments/Return';
import Faq from './views/Index/Faq';
import ConfirmNewsletter from './views/Index/ConfirmNewsletter';
import RevokeNewsletter from './views/Index/RevokeNewsletter';
import Landing from './views/Index/Landing';
import { ReferralProgram } from './views/Index/ReferralProgram';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<RouterErrorBoundary />}>
      <Route element={<FrontLayout />}>
        <Route index path="/:locale?" element={<Landing />} />
        <Route index path="/:locale/home/:section?" element={<Landing />} />
        <Route index path="/:locale/faq/:id" element={<Faq />} />
        <Route index path="/:locale/referral-program" element={<ReferralProgram />} />

        <Route path="/:locale/availability" element={<Availability />} />
        <Route path="/:locale/apply" element={<Apply />} />
        <Route path="/:locale/newsletter/confirm/:email/:code" element={<ConfirmNewsletter />} />
        <Route path="/:locale/newsletter/revoke/:email" element={<RevokeNewsletter />} />

        <Route path="/:locale/p/:url" element={<Page />} />
        <Route path="/:locale/n" element={<Newsfeed />} />
        <Route path="/:locale/n/c/:url" element={<Newsfeed />} />
        <Route path="/:locale/n/p/:url" element={<NewsfeedPost />} />
        <Route path="/:locale/h" element={<HelpIndex />} />
        <Route path="/:locale/h/c/:url" element={<HelpIndex />} />
        <Route path="/:locale/h/p/:url" element={<HelpPage />} />
        <Route path="/:locale/g" element={<GalleriesIndex />} />
        <Route path="/:locale/g/c/:url" element={<GalleriesIndex />} />
        <Route path="/:locale/g/g/:url" element={<Gallery />} />
      </Route>
      <Route path="/:locale/user/" element={<UserLayout />}>
        <Route index path="my-account" element={<MyAccountView />} />
        <Route path="subscription" element={<SubscriptionView />} />
        <Route path="invoices" element={<InvoicesView />} />
        <Route path="billing-info" element={<BillingInfoView />} />
        <Route path="payment-data" element={<PaymentDataView />} />
      </Route>
      <Route path="/:locale/payments/" element={<PaymentsLayout />}>
        <Route path="return" element={<ReturnPaymentView />} />
        <Route path="subscription/:id/:automaticPayment?" element={<SubscriptionPaymentView />} />
      </Route>
      <Route element={<SlimLayout />}>
        <Route path="/:locale/auth/sign-in" element={<SignIn />} />
      </Route>
    </Route>
  )
);
