import { PopoverButton } from '@headlessui/react';
import { twMerge } from 'tailwind-merge'

export default function SelectorOption(
  { children, active, onClick }: {
    children?: React.ReactNode | string;
    active?: boolean;
    onClick: () => void;
  }
) {
  return (
    <PopoverButton
      type="button"
      className={twMerge(
        "block px-4 py-2 text-sm text-gray-700 hover:bg-white/100 rounded-md w-full text-left",
        active && "bg-primary-100 text-primary-800"
      )}
      onClick={onClick}
    >
      {children}
    </PopoverButton>
  )
}