import { Container } from 'src/components/Container'
import { Button } from './Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone } from '@fortawesome/pro-duotone-svg-icons'
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'
import LocalizedLink from './Router/LocalizedLink'
import PageLink from './Cms/PageLink'
import * as CookieConsent from "vanilla-cookieconsent";
import { useTranslation } from 'react-i18next'
import RegionSelector from './i18n/RegionSelector'
import LocaleSelector from './i18n/LocaleSelector'
import PhoneNumber from './i18n/PhoneNumber'

export function Footer() {
  const { i18n, t } = useTranslation();
  return (
    <footer className="bg-slate-50">
      <Container>
        <div className="py-16">
          <div className="flex mx-auto">
            <div className="flex mx-auto">
              <img src="/images/logos/logo-full-color-transparent.webp" className="relative h-12 w-auto grayscale opacity-75" alt="Redini Logo" />
            </div>
          </div>
          <nav className="mt-10 text-sm" aria-label="quick links">
            <div className="-my-1 flex flex-col md:flex-row justify-center gap-x-6 gap-y-2">
              <LocalizedLink to="/home/features" className="transition-all hover:scale-105 active:scale-95">{t('landing.menu.features')}</LocalizedLink>
              <LocalizedLink to="/home/about" className="transition-all hover:scale-105 active:scale-95">{t('landing.menu.about')}</LocalizedLink>
              <LocalizedLink to="/home/referralProgram" className="transition-all hover:scale-105 active:scale-95">{t('landing.menu.referralProgram')}</LocalizedLink>
              <LocalizedLink to="/home/pricing" className="transition-all hover:scale-105 active:scale-95">{t('landing.menu.pricing')}</LocalizedLink>
              <LocalizedLink to="/home/contact" className="transition-all hover:scale-105 active:scale-95">{t('landing.menu.contact')}</LocalizedLink>
            </div>
          </nav>
        </div>
        <div className="mx-auto grid grid-cols-1 md:grid-cols-2 gap-3 lg:gap-6 justify-between">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 lg:gap-6 my-6 text-sm">
            <div>
              <div className="text-xs uppercase font-semibold mb-3">{t('service.header')}</div>
              <div className="mb-2 transition-all hover:scale-105 active:scale-95">
                <LocalizedLink to="/auth/sign-in">{t('auth.signIn.header')}</LocalizedLink>
              </div>
              <div className="mb-2 transition-all hover:scale-105 active:scale-95">
                <LocalizedLink to="/apply">{t('apply.header')}</LocalizedLink>
              </div>
              <div className="mb-2 transition-all hover:scale-105 active:scale-95">
                <LocalizedLink to="/availability">{t('availability.header')}</LocalizedLink>
              </div>
            </div>
            <div>
              <div className="text-xs uppercase font-semibold mb-3">{t('ui.documents')}</div>
              <div className="mb-2 transition-all hover:scale-105 active:scale-95">
                <PageLink id="3b9f58a4-2682-4fe3-b395-6313e8d1cf56" />
              </div>
              <div className="mb-2 transition-all hover:scale-105 active:scale-95">
                <PageLink id="b7cdaccb-08cc-49f8-82ad-739e1f738378" />
              </div>
              <div className="mb-2 transition-all hover:scale-105 active:scale-95">
                <a className="cursor-pointer" onClick={() => CookieConsent.showPreferences()}>{t('ui.cookiesPolicy')}</a>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 lg:gap-6 my-6">
            <div>
              <div>
                <div className="text-xs uppercase font-semibold mb-3">{t('contact.data.sales.name')}</div>
                <a href={`tel:+${[t('contact.data.sales.phoneNumber.0'), t('contact.data.sales.phoneNumber.1')].join('').replace('', '')}`}>
                  <Button color="secondary" className="w-full text-xs lg:text-sm">
                    <span>
                      <FontAwesomeIcon icon={faPhone} className="mr-2" />
                      <PhoneNumber phoneCode={Number(t('contact.data.sales.phoneNumber.0'))} phoneNumber={t('contact.data.sales.phoneNumber.1')} />
                    </span>
                  </Button>
                </a>
              </div>
              <div>
                <a href={`mailto:${t('contact.data.sales.email')}`}>
                  <Button color="secondary" className="mt-2 w-full text-xs lg:text-sm">
                    <span>
                      <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                      {t('contact.data.sales.email')}
                    </span>
                  </Button>
                </a>
              </div>
            </div>
            <div>
              <div>
                <div className="text-xs uppercase font-semibold mb-3">{t('contact.data.support.name')}</div>
                <a href={`tel:+${[t('contact.data.support.phoneNumber.0'), t('contact.data.support.phoneNumber.1')].join('').replace('', '')}`}>
                  <Button color="secondary" className="w-full text-xs lg:text-sm">
                    <span>
                      <FontAwesomeIcon icon={faPhone} className="mr-2" />
                      <PhoneNumber phoneCode={Number(t('contact.data.support.phoneNumber.0'))} phoneNumber={t('contact.data.support.phoneNumber.1')} />
                    </span>
                  </Button>
                </a>
              </div>
              <div>
                <a href={`mailto:${t('contact.data.support.email')}`}>
                  <Button color="secondary" className="mt-2 w-full text-xs lg:text-sm">
                    <span>
                      <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                      {t('contact.data.support.email')}
                    </span>
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between items-center mb-8 md:mb-0">
          <div className="flex gap-5 mb-5">
            <div>
              <a href="https://apps.apple.com/pl/app/redini-aplikacja-je%C5%BAdziecka/id6737122460" target="_blank" rel="noreferrer">
                <img className="h-12 w-auto transition-all hover:scale-105 active:scale-95" src={`/images/mobile/${i18n?.resolvedLanguage}/app-store.webp`} alt={t('ui.getFromAppStore')} title={t('ui.getFromAppStore')} />
              </a>
            </div>
            <div>
              <a href="https://play.google.com/store/apps/details?id=com.h0pe.rediniapp" target="_blank" rel="noreferrer">
                <img className="h-12 w-auto transition-all hover:scale-105 active:scale-95" src={`/images/mobile/${i18n?.resolvedLanguage}/google-play.webp`} alt={t('ui.getFromGooglePlay')} title={t('ui.getFromGooglePlay')} />
              </a>
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-x-8 gap-y-3 mb-5 w-full md:w-auto">
            <RegionSelector label anchor="top" />
            <LocaleSelector label anchor="top" />
          </div>
        </div>
        <div className="flex flex-col items-center border-t border-slate-400/10 py-10 sm:flex-row-reverse sm:justify-between">
          <div className="flex gap-x-6">
            <a href="https://www.facebook.com/redini.app/" target="_blank" className="transition-all hover:scale-105 active:scale-95" aria-label="Redini on Facebook">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a href="https://www.instagram.com/redini.app/" target="_blank" className="transition-all hover:scale-105 active:scale-95" aria-label="Redini on Instagram">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </div>
          <p className="mt-6 text-sm text-slate-500 sm:mt-0">
            &copy; {new Date().getFullYear()} {t('ui.copyright')}
          </p>
        </div>
      </Container>
    </footer >
  )
}
