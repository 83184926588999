import { Button } from 'src/components/Button'
import { Container } from 'src/components/Container'
import { TextAreaField, TextField } from './../Fields'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/pro-duotone-svg-icons'
import useApiConfiguration from 'src/hooks/useApiConfiguration'
import { ContactClient, ContactRequest } from 'src/api/notifications/Notifications'
import useForm from 'src/hooks/useForm'
import { useState } from 'react'
import { Dictionary } from 'lodash'
import useEventTracking from 'src/hooks/useEventTracking'
import { useTranslation } from 'react-i18next'

export function ContactForm() {
  const { t } = useTranslation();
  const apiConfiguration = useApiConfiguration();
  const apiClient = new ContactClient(apiConfiguration);
  const form = useForm({ subject: 'Formularz kontaktowy', personalData: { culture: 'pl' } } as ContactRequest);
  const [submitted, setSubmitted] = useState(false);
  const contactFormTracking = useEventTracking('generate_lead', { currency: 'PLN', value: 0.00, source: "contact form" });

  const onSubmit = () => {
    contactFormTracking();
    const data = form.data;
    if (data.personalData && !data.personalData?.surname)
      data.personalData.surname = "none";
    apiClient.send(data)
      .then(() => setSubmitted(true))
      .catch(form.catchAnyException)
      .finally(() => form.setPending(false));
  }

  const validate = () => {
    const result = {} as Dictionary<string[]>;
    return result;
  }

  return (
    <section id="contact">
      <Container className="pb-16 pt-20 text-center lg:pt-32 relative">
        {!submitted && <img src="/images/photos/auth.webp" className="hidden md:block absolute bottom-[10%] rounded-2xl drop-shadow-2xl w-1/2 opacity-90" />}
        <div className="flex flex-col md:flex-row md:justify-between">
          <div className="md:pt-32 md:px-8 lg:pt-0 px-0">
            <h1 className="mx-auto max-w-4xl font-display text-4xl sm:text-5xl font-medium tracking-tight text-slate-900">
              <span className="relative whitespace-nowrap text-secondary-500 tracking-tight">{t('landing.contact.cta')}</span> {t('landing.contact.withUs')}
            </h1>
            <p className="mx-auto mt-6 max-w-2xl text-xl tracking-tight text-slate-700 whitespace-pre-line">
              {t('landing.contact.slogan')}
            </p>
          </div>
          <div className="bg-slate-50 p-6 lg:p-10 rounded-2xl mt-6 shadow-2xl w-full md:w-1/2 lg:2-auto">
          <form onSubmit={e => form.onSubmit(e, onSubmit, validate)}>
            <div className="max-w-2xl mx-auto">
              {submitted && <div className="text-sm bg-primary-100 border-primary-300 text-primary-800 border p-3 my-6 rounded-3xl">
                <span className="font-medium">{t('contact.success.header')}</span> {t('contact.success.content')}
              </div>}
              {form.error && <div className="text-sm bg-red-100 border-red-300 text-red-800 border p-3 my-6 rounded-3xl">
                <span className="font-medium">{t('contact.error.header')}</span> {t('contact.error.content')}
              </div>}
              {!submitted &&
                <>
                  <div className="lg:mt-10 grid grid-cols-1 lg:grid-cols-2 gap-6 text-start">
                    <TextField
                      label={t('auth.fields.email')}
                      {...form.input(
                        'personalData.email',
                        'text', {
                        required: true,
                        placeholder: t('auth.fields.email'),
                        autoComplete: 'email'
                      })}
                    />
                    <TextField
                      label={t('auth.fields.phoneNumber')}
                      {...form.input(
                        'personalData.phoneNumber',
                        'text', {
                        placeholder: t('auth.fields.phoneNumber'),
                        autoComplete: 'tel'
                      })}
                    />
                    <TextField
                      label={t('auth.fields.givenName')}
                      {...form.input(
                        'personalData.givenName',
                        'text', {
                        required: true,
                        placeholder: t('auth.fields.givenName'),
                        autoComplete: 'given-name'
                      })}
                    />
                    <TextField
                      label={t('auth.fields.businessName')}
                      {...form.input(
                        'personalData.surname',
                        'text', {
                        placeholder: t('auth.fields.businessName'),
                        autoComplete: 'organization'
                      })}
                    />
                  </div>
                  <div className="my-6 text-start">
                    <TextAreaField
                      label={t('contact.fields.content')}
                      rows={10}
                      {...form.textArea(
                        'content',
                        'textarea', {
                        required: true,
                        placeholder: t('contact.fields.content'),
                      })}
                    />
                  </div>
                  <div className="text-end my-6">
                    <Button
                      color="primary"
                      className="w-full md:w-auto"
                      disabled={form.pending}
                    >
                      <FontAwesomeIcon icon={faPaperPlane} className="text-white" />
                      <span className="ml-3">{t('contact.action')}</span>
                    </Button>
                  </div>
                </>
              }
            </div>
          </form>
        </div>
        </div>
      </Container>
    </section>
  )
}
