/* eslint-disable @typescript-eslint/no-explicit-any */
'use client'

import {
  Popover,
  PopoverButton,
  PopoverBackdrop,
  PopoverPanel,
  PopoverGroup,
} from '@headlessui/react'
import { Button } from 'src/components/Button'
import { faKey, faPhone } from '@fortawesome/pro-solid-svg-icons'
import { faBarnSilo, faBell, faBrowser, faChartLineUp, faCogs, IconDefinition } from '@fortawesome/duotone-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LocalizedLink from './Router/LocalizedLink'
import useUser from 'src/hooks/useUser'
import { useTranslation } from 'react-i18next'
import RegionSelector from './i18n/RegionSelector'
import LocaleSelector from './i18n/LocaleSelector'
import PhoneNumber from './i18n/PhoneNumber'
import { faBars, faTimes } from '@fortawesome/pro-light-svg-icons'
import { twMerge } from 'tailwind-merge'
import Sections from 'src/data/pricing/Comparison.json'
import { Section, SectionTranslation } from './Landing/Pricing/DataTypes'
import useEntityTranslation from 'src/hooks/useEntityTranslation'

function MobileNavIcon({ open }: { open: boolean }) {
  return (
    <FontAwesomeIcon icon={open ? faTimes : faBars} className="size-6" />
  )
}

const sections = Sections as Section[];

function MobileNavigation() {
  const { t } = useTranslation();
  const sectionTranslation = useEntityTranslation<Section, SectionTranslation>();
  const sectionsInMenu = sections.filter(s => s.id !== 'others');
  return (
    <Popover>
      <PopoverButton
        className="z-20 flex h-8 w-8 items-center justify-center ui-not-focus-visible:outline-none"
        aria-label="Toggle Navigation"
      >
        {({ open }: any) => <MobileNavIcon open={open} />}
      </PopoverButton>
      <PopoverGroup>
        <PopoverBackdrop
          transition
          className="fixed inset-0 z-30 bg-slate-300/50 duration-150 data-[closed]:opacity-0 data-[enter]:ease-out data-[leave]:ease-in"
        />
        <PopoverPanel
          transition
          modal
          portal
          className="fixed inset-0 z-40 bg-white/60 backdrop-blur-lg backdrop-filter p-4 flex flex-col md:flex-row pt-24 overflow-x-auto"
        >
          <div className="flex flex-col gap-y-4 md:flex-1 pb-4">
            <div className="text-xs uppercase text-gray-500 font-medium">{t('ui.menu')}</div>
            <PopoverButton as={LocalizedLink} to="/home/features" className="transition-all hover:scale-105 active:scale-95">{t('landing.menu.product')}</PopoverButton>
            {sectionsInMenu.map((section, i) => (
              <PopoverButton key={`mobile-nav-product-${i}`} as={LocalizedLink} to={`/home/pricing-premium-${section.id}`} className="pl-5 transition-all hover:scale-105 active:scale-95">{sectionTranslation.getCurrentTranslation(section)?.name}</PopoverButton>
            ))}
            <PopoverButton as={LocalizedLink} to="/home/pricing" className="transition-all hover:scale-105 active:scale-95">{t('landing.menu.pricing')}</PopoverButton>
            <PopoverButton as={LocalizedLink} to="/home/contact" className="transition-all hover:scale-105 active:scale-95">{t('landing.menu.contact')}</PopoverButton>
            <hr className="m-2 border-white/90 block md:hidden" />
          </div>
          <div className="flex flex-col gap-y-4 md:flex-1">
            <div className="flex flex-row w-full gap-x-1">
              <PopoverButton as={LocalizedLink} to="/apply" className="flex-1">
                <Button color="primary" className="w-full">
                  {t('landing.menu.tryForFree')}
                </Button>
              </PopoverButton>
              <PopoverButton as={LocalizedLink} to="/auth/sign-in" className="flex-1 w-full">
                <Button variant="outline" color="secondary" className="w-full">
                  {t('auth.signIn.header')}
                </Button>
              </PopoverButton>
            </div>
            <hr className="m-2 border-white/90" />
            <div className="text-xs uppercase text-gray-500 font-medium">{t('ui.regionAndLanguage')}</div>
            <div className="flex flex-col md:flex-row gap-x-8 gap-y-5 mb-5 w-full md:w-auto">
              <RegionSelector label anchor="top" />
              <LocaleSelector label anchor="top" />
            </div>
          </div>
        </PopoverPanel>
      </PopoverGroup>
    </Popover>
  )
}

export function ProductIcon({ icon }: { icon: IconDefinition }) {
  return (
    <div className="bg-primary-700 group-hover:bg-secondary-500 group-active:bg-secondary-600 text-white rounded-full p-1 size-8 text-center">
      <FontAwesomeIcon icon={icon} />
    </div>
  )
}

export function PopoverItem({ to, children, className }: { to: string, children: React.ReactNode, className?: string }) {
  return (
    <PopoverButton as={LocalizedLink} to={to} className={twMerge("overflow-hidden px-5 py-3 flex flex-row gap-x-5 items-center group cursor-pointer hover:text-secondary-500 active:text-secondary-600", className)}>
      {children}
    </PopoverButton>
  )
}

export function ProductPopover() {
  const { t } = useTranslation();
  const sectionTranslation = useEntityTranslation<Section, SectionTranslation>();
  const sectionsInMenu = sections.filter(s => s.id !== 'others');
  const getSectionIcon = (section: Section): IconDefinition | undefined => {
    switch(section.id) {
      case 'cms':
        return faBrowser;
      case 'booking':
        return faCogs;
      case 'stable':
        return faBarnSilo;
      case 'notifications':
        return faBell;
      case 'analytics':
        return faChartLineUp;
      default:
        return undefined;
    }
  }
  return (
    <div className="relative inline-block text-left">
      <Popover>
        <PopoverButton className="transition-all hover:scale-105 active:scale-95">
          {t('landing.menu.product')}
        </PopoverButton>
          <PopoverPanel
            anchor="bottom start"
            className="absolute z-50 min-w-32 max-w-72 mt-2 origin-top-right bg-white/60 backdrop-filter backdrop-blur-lg divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <PopoverItem to="/home/features" className="bg-slate-50"><img src="/apple-touch-icon.png" className="size-8 rounded-full" /><div className="text-lg font-medium">Redini</div></PopoverItem>
            {sectionsInMenu.map((section, i) => {
              const icon = getSectionIcon(section);
              return (
                <PopoverItem key={`desktop-nav-product-${i}`} to={`/home/pricing-${section.id}`}>{icon && <ProductIcon icon={icon} />}<div className="text-lg">{sectionTranslation.getCurrentTranslation(section)?.name}</div></PopoverItem>
              )
            })}
          </PopoverPanel>
      </Popover>
    </div>
  )
}

export function Header() {
  const user = useUser();
  const { t } = useTranslation();

  return (
    <>
      <header className="py-3 fixed top-0 start-0 left-0 bg-white/60 w-full z-50 px-3 xl:px-12 2xl:px-16 shadow-md backdrop-blur-lg backdrop-filter">
        <nav className="z-50 flex justify-between">
          <div className="flex items-center gap-x-1 md:gap-x-3 xl:gap-x-8">
            <LocalizedLink to="/" aria-label="Home" className="flex transition-all hover:scale-105 active:scale-95" onClick={() => window.scrollTo(0, 0)}>
              <img src="/images/logos/logo-full-color-transparent.webp" className="relative h-12 w-auto hidden xl:inline" alt="Redini Logo" />
              <div className="inline xl:hidden">
                <img src="/images/logos/logo-color-transparent-no-padding.webp" className="h-8 w-auto" alt="Redini Logo" />
              </div>
            </LocalizedLink>
            <div className="hidden lg:flex lg:gap-x-10 items-center">
              <ProductPopover />
              <LocalizedLink to="/home/pricing" className="transition-all hover:scale-105 active:scale-95">{t('landing.menu.pricing')}</LocalizedLink>
              <LocalizedLink to="/home/contact" className="transition-all hover:scale-105 active:scale-95">{t('landing.menu.contact')}</LocalizedLink>
              <LocalizedLink to="/apply" className="hidden lg:block">
                <Button color="primary">
                  {t('landing.menu.tryForFree')}
                </Button>
              </LocalizedLink>
            </div>
          </div>
          <div className="flex items-center gap-x-1 md:gap-x-3 xl:gap-x-8">
            <RegionSelector anchor="bottom" className="hidden lg:block 2xl:hidden mt-1" short withoutChevron />
            <RegionSelector anchor="bottom" className="hidden 2xl:block mt-1" short />
            <LocaleSelector anchor="bottom" className="hidden lg:block 2xl:hidden" short withoutChevron />
            <LocaleSelector anchor="bottom" className="hidden 2xl:block" short />
            <div className="hidden lg:block transition-all hover:scale-105 active:scale-95">
              {!user &&
                <LocalizedLink to="/auth/sign-in">
                  <FontAwesomeIcon icon={faKey} className="mr-2" />
                  <span className='md:hidden xl:inline'>{t('auth.signIn.header')}</span>
                </LocalizedLink>
              }
              {user &&
                <LocalizedLink to="/user">
                  <FontAwesomeIcon icon={faCogs} className="mr-2" />
                  <span className='md:hidden xl:inline'>{t('panel.header')}</span>
                </LocalizedLink>
              }
            </div>

            <LocalizedLink to="/apply" className="lg:hidden">
              <Button color="primary" className="text-xs md:text-sm font-normal md:font-medium">
                {t('landing.menu.tryForFree')}
              </Button>
            </LocalizedLink>
            <a href={`tel:+${[t('contact.data.sales.phoneNumber.0'), t('contact.data.sales.phoneNumber.1')].join('').replace('', '')}`}>
              <Button color="secondary" className="flex gap-x-1 xl:gap-x-2 text-xs md:text-sm font-normal md:font-medium">
                <FontAwesomeIcon icon={faPhone} />
                <span>
                  <PhoneNumber phoneCode={Number(t('contact.data.sales.phoneNumber.0'))} phoneNumber={t('contact.data.sales.phoneNumber.1')} />
                </span>
              </Button>
            </a>
            <div className="-mr-1 lg:hidden">
              <MobileNavigation />
            </div>
          </div>
        </nav>
      </header>
      <div className="mt-18"></div>
    </>
  )
}
