import { useTranslation } from "react-i18next";
import LocalizedLink from "../Router/LocalizedLink";

export default function CallToAction() {
  const { t } = useTranslation();
  return (
    <div className="relative -mt-16" id="cta">
      <div className="relative h-80 rounded-lg md:absolute md:left-0 md:h-full md:w-1/3 lg:w-1/2 transform -translate-x-24 md:-translate-x-8 lg:-translate-x-24">
        <div className="absolute bg-primary-700 opacity-25 rounded-lg -top-[5%] size-[110%]" />
        <div className="absolute bg-primary-900 inset-0 rounded-lg size-full">
          <img
            alt="Call to Action"
            src="/images/photos/cta.webp"
            className="size-full object-cover opacity-25 grayscale"
          />
        </div>
      </div>
      <div className="relative mx-auto max-w-7xl py-16 lg:px-8 lg:py-40">
        <div className="pl-6 pr-6 md:ml-auto md:w-2/3 md:pl-16 lg:w-1/2 lg:pl-24 lg:pr-0 xl:pl-32">
          <h2 className="font-medium text-secondary-500">{t('landing.cta.leading')}</h2>
          <p className="mt-2 text-4xl font-medium font-branding tracking-tight text-gray-700 lg:text-6xl">{t('landing.cta.header')}</p>
          <p className="mt-6 text-lg text-gray-600">
            {t('landing.cta.slogan')}
          </p>
          <div className="mt-8">
            <LocalizedLink
              to="/apply"
              className="inline-flex rounded-md bg-secondary-600 px-5 py-3 font-semibold text-white shadow-sm hover:bg-secondary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white  transition-all hover:scale-105 active:scale-95"
            >
              {t('landing.cta.button')}
            </LocalizedLink>
          </div>
        </div>
      </div>
    </div>
  )
}