import configuration from 'src/config/config';
import Selector from '../Selector/Selector';
import SelectorOption from '../Selector/SelectorOption';
import LocaleName from './LocaleName';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

export default function LocaleSelector({
  label,
  short,
  anchor,
  withoutChevron,
  className
}: { label?: boolean, short?: boolean, withoutChevron?: boolean, anchor?: "top" | "bottom", className?: string }) {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className={twMerge("flex flex-col gap-y-3", className)}>
        {label && <span className="font-medium text-xs text-gray-500">{t('ui.language')}</span>}
        <Selector
          button={(!short ? <span><LocaleName locale={i18n.resolvedLanguage ?? 'pl'} /></span> : <span>{i18n.resolvedLanguage?.toUpperCase()}</span>)}
          anchor={anchor}
          withoutChevron={withoutChevron}
        >
          {configuration.locales.map((l) => (
            <SelectorOption onClick={() => i18n.changeLanguage(l)} active={l === i18n.resolvedLanguage} key={l}>
              <div className="flex">
                <span className="mr-8"><LocaleName locale={l} /></span>
                <span className="ml-auto">{l.toUpperCase()}</span>
              </div>
            </SelectorOption>
          ))}
        </Selector>
      </div>
    </>);
}

