import { useTranslation } from "react-i18next";
import NewsletterBanner from "../NewsletterBanner";

export default function Newsletter() {
  const { t } = useTranslation();
  return (
    <div className="relative overflow-hidden px-8 pt-20 lg:pt-24">
      <div className="relative h-80 rounded-lg md:absolute md:right-0 md:h-full w-full md:w-1/2 lg:h-[80%] transform translate-x-16 md:translate-x-24">
        <div className="absolute bg-primary-900 inset-0 rounded-2xl size-full">
          <img
            alt=""
            src="/images/photos/license.webp"
            className="size-full object-cover opacity-25 grayscale rounded-2xl"
          />
          <h1 className="absolute top-[10%] left-[10%] text-white text-3xl lg:text-7xl">{t('landing.newsletter.header')}</h1>
        </div>
      </div>
      <svg
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
        aria-hidden="true"
        className="absolute inset-y-0 right-0 hidden h-full fill-secondary-500 opacity-15 lg:block"
      >
        <polygon points="100,0 50,70 50,70 100,100" />
      </svg>
      <svg
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
        aria-hidden="true"
        className="absolute inset-y-0 right-0 hidden h-full fill-secondary-500 opacity-15 lg:block"
      >
        <polygon points="100,0 25,70 25,70 100,100" />
      </svg>
      <div className="relative mx-auto max-w-7xl py-16 lg:px-8 lg:py-40">
        <div className="lg:pr-6 md:mr-auto md:w-2/3 lg:w-1/2">
          <h2 className="font-medium text-secondary-500">{t('landing.newsletter.cta')}</h2>
          <p className="mt-2 text-4xl font-medium font-branding tracking-tight text-gray-700 sm:text-6xl">{t('landing.newsletter.leading')}</p>
          <div className="lg:flex lg:flex-row">
            <div className="bg-slate-50 rounded-2xl p-6 lg:p-12 mt-6 shadow-2xl">
              <NewsletterBanner />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}