import { useTranslation } from "react-i18next";
import getCountryName from "./getCountryName";
import { twMerge } from "tailwind-merge";

export const CountryFlag = (props: {
  countryCode: string,
  className?: string
}) => {
  const { className } = props;
  const code = props.countryCode.toLowerCase();
  const { i18n } = useTranslation();
  const alt = getCountryName(code, i18n.resolvedLanguage);
  return (
    <img
      src={`https://flagcdn.com/w80/${code}.webp`}
      width="20"
      alt={alt}
      className={twMerge("ring-1 ring-gray-300/30 rounded-sm h-4 w-auto", className)}
    />
  )
}