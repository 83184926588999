import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { ClientHelpCategoriesClient, ClientHelpPagesClient, Content, ContentTranslation, ProblemDetails } from "src/api/cms/Cms";
import Spinner from "src/components/Feedback/Spinner";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import useEntityTranslation from "src/hooks/useEntityTranslation";

import useApplicationDispatch from "src/hooks/useApplicationDispatch";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { setBreadcrumbs } from "src/store/application/actions";
import useLocalizedNavigate from "src/hooks/useNavigate";

import ContentParse from "src/components/Cms/ContentParse";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import { MetaContentHead } from "src/components/MetaHead";

export interface HelpPageElementComponentProps {
  page: Content;
}

export const HelpPageHeader = (props: HelpPageElementComponentProps) => {
  const { page } = props;
  const { i18n } = useTranslation();
  const entityTranslation = useEntityTranslation<Content, ContentTranslation>();
  const translation = entityTranslation.getCurrentTranslation(page);
  return (
    <>
      <MetaContentHead content={page} />
      <div className="flex border-b border-gray-200 pb-5 mt-12 mb-5 justify-between">
        <h2 className="text-3xl tracking-tight text-primary-700 sm:text-4xl">{translation?.title}</h2>
        <time dateTime={page.created?.toLocaleString()} className="text-white mt-3 bg-gray-400 rounded-md px-3 py-1 text-sm">
          {page.created?.toLocaleString(i18n.resolvedLanguage, { dateStyle: 'short', timeStyle: 'short' })}
        </time>
      </div>
    </>
  )
}

export const HelpPageContent = (props: HelpPageElementComponentProps) => {
  const { page } = props;
  const entityTranslation = useEntityTranslation<Content, ContentTranslation>();
  const translation = entityTranslation.getCurrentTranslation(page);
  return (
    <div className="border-b border-gray-200 pb-5 mb-5">
      <ContentParse>{translation?.content}</ContentParse>
    </div>
  )
}

export const HelpPageFooter = (props: HelpPageElementComponentProps) => {
  const { page } = props;
  const { i18n } = useTranslation();
  const entityTranslation = useEntityTranslation<Content, ContentTranslation>();
  const translation = entityTranslation.getCurrentTranslation(page);
  return (
    <div className="border-b border-gray-200 pb-5 flex justify-between text-xs text-gray-400">
      <div>
        <FontAwesomeIcon icon={faTag} className="inline h-3 mb-1 mr-3" /> {translation?.meta?.keywords}
        &nbsp;
      </div>
      <div>
        {page.updated && <span> Zaktualizowano: {page.updated?.toLocaleString(i18n.resolvedLanguage)}</span>}
      </div>
    </div>
  )
}

const HelpPage = () => {
  const { url } = useParams<string>();
  const { i18n } = useTranslation();
  const apiConfiguration = useApiConfiguration();
  const apiClient = new ClientHelpPagesClient(apiConfiguration);
  const categoriesClient = new ClientHelpCategoriesClient(apiConfiguration);
  const entityTranslation = useEntityTranslation<Content, ContentTranslation>();
  const applicationDispatch = useApplicationDispatch();
  const navigate = useLocalizedNavigate();

  const [page, setPage] = useState<Content | undefined>();
  const [category, setCategory] = useState<Content | undefined>();
  const translation = entityTranslation.getCurrentTranslation(page);

  const onError = (e: ProblemDetails) => {
    switch (e.status) {
      case 404:
        navigate('/404');
        break;
      default:
        console.error(e);
        break;
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    apiClient.findByUrl(url || "")
      .then(response => setPage(response))
      .catch(onError);
  }, [url, i18n?.resolvedLanguage]);

  useEffect(() => {
    if (page?.parentId) {
      categoriesClient.findById(page?.parentId)
        .then(response => setCategory(response))
        .catch(onError);
    }
  }, [page]);

  useEffect(() => {
    const breadcrumbs = [{ label: 'cms.help.pages.group', href: `/h` }];
    if (category) {
      const categoryTranslation = entityTranslation.getCurrentTranslation(category);
      breadcrumbs.push({ label: categoryTranslation?.meta?.title || "Untitled", href: `/h/c/${categoryTranslation?.url ?? category?.id}` });
    }
    if (page) {
      breadcrumbs.push({ label: translation?.meta?.title || "Untitled", href: `/h/p/${url}` });
    }
    applicationDispatch(setBreadcrumbs(breadcrumbs));
  }, [page, category]);

  if (!page) {
    return <Spinner className="h-24" />;
  }

  return (
    <>

      <div className="px-3 md:px-0 max-w-sm md:max-w-xl lg:max-w-3xl xl:max-w-7xl mx-auto pt-20 lg:pt-24">
        <HelpPageHeader page={page} />
        <HelpPageContent page={page} />
        <HelpPageFooter page={page} />
      </div>
    </>
  )
}

export default HelpPage;