import { useTranslation } from "react-i18next";

const PaidInfo = () => {
  const { t } = useTranslation();
  return (
    <div className="p-5 flex-1">
      <h3 className="text-gray-500 mb-3">{t('licensing.paymentStatus')}</h3>
      <h2 className="text-gray-700 text-xl pt-1">
        <span className="text-emerald-700">{t('licensing.paid')}</span>
      </h2>
      <h4 className="text-gray-400 text-sm mt-5">{t('licensing.paymentAvailability')}</h4>
    </div>
  )
}

export default PaidInfo;